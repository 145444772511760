<template>
  <div class="v-overflow v-overflow-bt-padding ads-grid">



    <!-- start section header -->

    <div class="header"
      style="    position: fixed;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    min-width: 100vw;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    min-height: 54px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    background-color: white;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    z-index: 100;">
      <div class="container-fluid">

        <div class="row">

          <div class="col-lg-2 col-md-6 col-6" style="display:flex;align-items:center;">


            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon id="no-background-hover" to="/initSearchAds" style="margin-left: 27px;">

                  <v-img :src="pathLogo" style="width:100px" />

                </v-btn>
              </template>

            </v-tooltip>


          </div>

          <div class="col-lg-10 col-md-6 col-6" id="divHeadBtns">

            <ul style="display:flex;justify-content:end;" class="navbar-nav">


              <li class="nav-item">
                <a id="btnAllJobs" class="nav-link">
                  <img src="@/assets/all_jobs.jpg" class="clButtonHeader" title="Jobs" />
                </a>
              </li>

              <li class="nav-item">
                <div class="clPipe"></div>
              </li>

              <li class="nav-item">
                <a id="btnSignInCompany" class="nav-link">
                  <img src="@/assets/login_aziende.jpg" class="clButtonHeader" title="Login Aziende" />
                </a>
              </li>

              <li class="nav-item">
                <a id="btnSignInCandidate" class="nav-link">
                  <img src="@/assets/login_candidati.jpg" class="clButtonHeader" title="Login Candidati" />
                </a>
              </li>

            </ul>

          </div>


          <div class="col-lg-10 col-md-6 col-6" id="divHeadBtnsJobmule">

            <ul style="display:flex;justify-content:end;" class="navbar-nav">



              <li class="nav-item">
                <a id="btnAllJobs" class="nav-link">
                  <img src="@/assets/all_jobs_jobmule_home.jpg" class="clButtonHeader" title="Jobs" />
                </a>
              </li>

              <li class="nav-item">
                <div class="clPipe"></div>
              </li>

              <li class="nav-item">
                <a id="btnSignInCompany" class="nav-link">
                  <img src="@/assets/login_aziende_jobmule_home.jpg" class="clButtonHeader" title="Login Aziende" />
                </a>
              </li>

              <li class="nav-item">
                <a id="btnSignInCandidate" class="nav-link">
                  <img src="@/assets/login_candidati_jobmule_home.jpg" class="clButtonHeader" title="Login Candidati" />
                </a>
              </li>

            </ul>

          </div>




        </div>

      </div>


    </div>

    <!-- end section header -->

    <!-- start section title -->

    <div class="pageTitle" style="margin-top:75px">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <h1 class="page-heading" style="font-family: 'Orbitron', sans-serif!important;">Job Listing</h1>
          </div>
          <div class="col-md-6 col-sm-6">
            <!-- <div class="breadCrumb"><a id="gotToHome"
                style="font-family: 'Orbitron', sans-serif!important;font-size:14px">Home</a> /
              <span style="font-family: 'Orbitron', sans-serif!important;font-size:14px">Job Listing</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- end section title -->


    <!-- start section search -->

    <form method="get">
      <div class="pageSearch">
        <div class="container">
          <div class="searchform">
            <div class="row">
              <div class="col-lg-9">
                <input type="text" name="search" id="search" value="" class="form-control"
                  style="font-family: 'Orbitron', sans-serif!important;" :placeholder="txtSearchKeywordsPlaceholder">
              </div>

              <div class="col-lg-3">
                <button type="button" class="btn imgSearchJob"
                  style="font-family: 'Orbitron', sans-serif!important;font-size:16px!important;margin-top:0px!important"><i
                    class="fa fa-search" aria-hidden="true"></i> Search Jobs</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- end section search -->


    <!-- start section search result and slidebar -->


    <div class="listpgWraper">

      <div class="container">



        <form action="https://app.lc-service.it/jobs" method="get">

          <!-- Search Result and sidebar start -->

          <div class="row">

            <div class="col-md-3 col-sm-6">
              <!-- <div class="jobreqbtn">
                <a class="btn btn-job-alert-disabled" disabled="" href="javascript:;">
                  <i class="fa fa-bell" style="font-size:1.125rem;"></i> Save Job Alert</a>


                <a href="https://app.lc-service.it/my-profile#cvs" class="btn"><i class="fa fa-file-text"
                    aria-hidden="true"></i> Upload Your Resume</a>
              </div> -->
              <!-- Side Bar start -->
              <div class="sidebar">
                <input type="hidden" name="search" value="">

                <!-- Jobs By Title -->
                <div class="widget">
                  <h4 class="widget-title">Jobs By Title</h4>


                  <div id="divJobsByTitle">


                  </div>




                  <!-- title end -->
                  <span class="text text-primary view_more" id="btnViewMoreJobsByTitle">View More</span>
                </div>




                <!-- Jobs By State -->
                <div class="widget">
                  <h4 class="widget-title">Jobs By Region</h4>

                  <div id="divJobsByState">


                  </div>


                  <span class="text text-primary view_more hide_vm" id="btnViewMoreJobsByState">View More</span>
                </div>
                <!-- Jobs By State end-->

                <!-- Jobs By City -->
                <div class="widget">
                  <h4 class="widget-title">Jobs By City</h4>

                  <div id="divJobsByCity">


                  </div>


                  <span class="text text-primary view_more hide_vm" id="btnViewMoreJobsByCity">View More</span>
                </div>
                <!-- Jobs By City end-->

                <!-- Jobs By Industry -->
                <!-- <div class="widget">
                  <h4 class="widget-title">Jobs By Industry</h4>

                  <div id="divJobsByIndustry">


                  </div>

                  <span class="text text-primary view_more hide_vm" id="btnViewMoreJobsByIndustry">View More</span>
                </div> -->
                <!-- Jobs By Industry end -->



                <!-- Jobs By Skill -->
                <!-- <div class="widget">
                  <h4 class="widget-title">Jobs By Skill</h4>

                  <div id="divJobsBySkill">


                  </div>


                  <span class="text text-primary view_more" id="btnViewMoreJobsBySkill">View More</span>
                </div> -->
                <!-- Jobs By Skill end -->


                <!-- Jobs By Functional Areas -->
                <div class="widget">
                  <h4 class="widget-title">Jobs By Functional Areas</h4>

                  <div id="divJobsByFunc">


                  </div>


                  <span class="text text-primary view_more" id="btnViewMoreJobsByFunc">View More</span>
                </div>
                <!-- Jobs By Functional Areas end -->











                <!-- Salary -->
                <div class="widget" style="display:block;">



                  <!-- button -->
                  <div class="searchnt">
                    <button type="button" class="btn" id="btnSearchJobsWithFilters"><i class="fa fa-search"
                        aria-hidden="true"></i> Search
                      Jobs</button>
                  </div>
                  <!-- button end-->
                </div>
                <!-- Side Bar end -->
              </div>
            </div>


            <div class="col-lg-9 col-sm-12">

              <!-- Search List -->

              <div id="divSearchResult">


              </div>



              <!-- Pagination Start -->

              <div class="pagiWrap">

                <div class="row">

                  <div class="col-md-5">

                    <div class="showreslt">


                    </div>

                  </div>

                  <div class="col-md-7 text-right">





                  </div>

                </div>

              </div>

              <!-- Pagination end -->





            </div>



            <div class="col-lg-3 col-sm-6 pull-right hide" style="display:none;">

              <!-- Sponsord By -->

              <div class="sidebar">

                <h4 class="widget-title">Sponsord By</h4>

                <div class="gad"></div>

              </div>

            </div>



          </div>

        </form>

      </div>

    </div>


    <!-- end section search result and slidebar -->


    <!-- start section footer -->

    <div class="footerWrap">
      <div class="container">
        <div class="row">

          <!--Quick Links-->
          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Quick Links</h5>
            <!--Quick Links menu Start-->
            <ul class="quicklinks">



              <li class="">
                <a style="font-family: 'Orbitron', sans-serif!important;" class="lnkTermOfUse">Terms Of Use</a>
                <a style="font-family: 'Orbitron', sans-serif!important;" class="lnkPrivacyHome">Privacy</a>

              </li>
            </ul>
          </div>
          <!--Quick Links menu end-->

          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Jobs By Functional Area</h5>
            <!--Quick Links menu Start-->

            <ul class="quicklinks" id="jobsByFuncAreaQuickLinks">




            </ul>

          </div>

          <!--Jobs By Industry-->
          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Jobs By Industry</h5>
            <!--Industry menu Start-->

            <ul class="quicklinks" id="jobsByIndustryQuickLinks">

            </ul>


            <!--Industry menu End-->
            <div class="clear"></div>
          </div>

          <!--About Us-->
          <div class="col-md-3 col-sm-12">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Contact Us</h5>

            <div class="txtInfoCompany" style="font-family: 'Orbitron', sans-serif!important;color:white">


            </div>



            <div class="social"></div>
            <!-- Social Icons end -->

          </div>
          <!--About us End-->


        </div>
      </div>
    </div>

    <!-- end section footer -->

    <!-- start section copyright -->


    <div class="copyright" style="padding:0!important">
      <div class="container">
        <div class="row">
          <div class="col-md-8">


            <div class="bttxt">

            </div>



          </div>
          <div class="col-md-4">

          </div>
        </div>

      </div>
    </div>

    <!-- end section copyright -->


    <div class="contpopupotpdelete" style="display:none">

      <template>
        <modal name="popupOtpDelete" :clickToClose="false" :width="262" :height="215">


          <v-row>

            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

              Inserire codice OTP ricevuto via mail

            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" md="12">

              <v-text-field type="password" v-model="valueOtpDelete" label="" id="fldValueOtpDelete">
              </v-text-field>

            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">

                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_careers.png"
                  max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                  transition="scale-transition" title="Annulla" @click="btnCancelOtpDelete" />

                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_careers.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmOtpDelete" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>

    <div class="contpopupprivacy" style="display:none">

      <template>
        <modal name="popupPrivacy" :clickToClose="false" :width="getWindowMobileTermOfUse()" :height="460">


          <v-row>

            <v-col cols="12" md="12">


              <div class="clPrivacy">



              </div>



            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">


                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_jobmule.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmPrivacy" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>


    <div class="contpopuptermuse" style="display:none">

      <template>
        <modal name="popupTermUse" :clickToClose="false" :width="getWindowMobileTermOfUse()" :height="460">


          <v-row>

            <v-col cols="12" md="12">


              <div class="clTermOfUse">



              </div>



            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">


                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_jobmule.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmTermOfUse" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>





    <div class="contpopupprivacyhome" style="display:none">

      <template>
        <modal name="popupPrivacyHome" :clickToClose="false" :width="getWindowMobilePrivacyHome()" :height="460">


          <v-row>

            <v-col cols="12" md="12">


              <div class="clPrivacyHome">



              </div>



            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">


                <v-img alt="" class="btn-confirm-privacy-home" contain src="@/assets/btn_confirm3_careers.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmPrivacyHome" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>





  </div>
</template>


<style scoped lang="css">
@import "./../assets/annunci/bootstrap-datepicker3.min.css";
@import "./../assets/annunci/bootstrap.min.css";
@import "./../assets/annunci/main.css";
/* @import "./../assets/annunci/owl.carousel.css"; */
@import "./../assets/annunci/select2-bootstrap.min.css";
@import "./../assets/annunci/select2.min.css";
/* @import "./../assets/annunci/settings.css"; */
/* @import "./../assets/annunci/slick-theme.css"; */
/* @import "./../assets/annunci/slick-theme.min.css"; */
@import "./../assets/annunci/slick.css";
@import "./../assets/annunci/style-font1.css";
@import "./../assets/annunci/style-font2.css";
@import "./../assets/annunci/font-awesome.css";
</style>

<script>
//@import "@/assets/loginstart/style.css"
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

//import RiseLoader from "vue-spinner/src/RiseLoader.vue";

import router from ".././router";
import apisearchads from "../utils/searchads/apisearchads";
import apiotp from "../utils/otp/apiotp";
import apicareers from "../utils/careers/apicareers";

import $ from 'jquery';

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {
  // components: {
  //   /* PulseLoader, */
  //   RiseLoader,
  // },

  updated() { },


  mounted: function () {

    this.setUrlDomainGlobal();


    $("#divHeadBtnsJobmule").hide();

    this.txtSearchKeywordsPlaceholder = "Enter Skills, job title or job description or job location";

    if (window.urlSiteCareers == "https://jobmule.eu/") {

      $("#divHeadBtns").hide();
      $("#divHeadBtnsJobmule").show();

      this.txtSearchKeywordsPlaceholder = "Enter Skills, job title or job description or job location or company";

    }



    console.log("ID COMPANY CONF: ", window.companyidcareers);



    $(".pageTitle").css("background", "url('')");

    $('.termOfUse').prop('checked', false);

    $('#btnApply').hide();



    window.myVal = "";

    var loggedIn = this.$cookies.get('token');

    if (loggedIn) {
      router.push({ path: "/dash" });
    }
    console.log("Ciao: " + this.userLogged);

    var pointerVue = this;

    setTimeout(() => {

      pointerVue.getValuesSearch();

      pointerVue.searchJobs();

    }, 100);


    // $("#divMenu").hide();


    console.log("RELOAD ONCE: ", window.reloadOnce);



    $(document).ready(function () {

      $('body').on('click', '.lnkTermOfUse', function (event) {
        event.preventDefault();


        $(".contpopuptermuse").show();

        pointerVue.$modal.show('popupTermUse');


        setTimeout(() => {

          $(".clTermOfUse").html(pointerVue.txtTermOfUse);

        }, 200);


      });


      $('body').on('click', '.lnkPrivacyHome', function (event) {
        event.preventDefault();


        router.push({ path: '/privacyHome' }).catch(() => { });



      });



      $('body').on('click', '#btnSearchJobsWithFilters', function (event) {
        event.preventDefault();

        console.log("SEARCH WITH FILTERS");


        // job by title

        var str_sel_job_by_title = "";

        $(".chkJobTitle").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_title != "") {
              str_sel_job_by_title = str_sel_job_by_title + ",";
            }
            str_sel_job_by_title = str_sel_job_by_title + $(this).val();

          }

        });


        console.log("JOB BY TITLE SEL: ", str_sel_job_by_title);

        window.$cookies.set("filter_ads_job_by_title", str_sel_job_by_title, "9y");


        // job by country

        var str_sel_job_by_country = "";

        $(".chkJobCountry").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_country != "") {
              str_sel_job_by_country = str_sel_job_by_country + ",";
            }
            str_sel_job_by_country = str_sel_job_by_country + $(this).val();

          }

        });


        console.log("JOB BY COUNTRY SEL: ", str_sel_job_by_country);

        window.$cookies.set("filter_ads_job_by_country", str_sel_job_by_country, "9y");


        // job by state / region

        var str_sel_job_by_state = "";

        $(".chkJobState").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_state != "") {
              str_sel_job_by_state = str_sel_job_by_state + ",";
            }
            str_sel_job_by_state = str_sel_job_by_state + $(this).val();

          }

        });


        console.log("JOB BY STATE SEL: ", str_sel_job_by_state);

        window.$cookies.set("filter_ads_job_by_state", str_sel_job_by_state, "9y");


        // job by city

        var str_sel_job_by_city = "";

        $(".chkJobCity").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_city != "") {
              str_sel_job_by_city = str_sel_job_by_city + ",";
            }
            str_sel_job_by_city = str_sel_job_by_city + $(this).val();

          }

        });


        console.log("JOB BY CITY SEL: ", str_sel_job_by_city);

        window.$cookies.set("filter_ads_job_by_city", str_sel_job_by_city, "9y");

        // job by experience

        var str_sel_job_by_exp = "";

        $(".chkJobExp").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_exp != "") {
              str_sel_job_by_exp = str_sel_job_by_exp + ",";
            }
            str_sel_job_by_exp = str_sel_job_by_exp + "'" + $(this).val() + "'";

          }

        });


        console.log("JOB BY EXP SEL: ", str_sel_job_by_exp);

        window.$cookies.set("filter_ads_job_by_exp", str_sel_job_by_exp, "9y");




        // job by type

        var str_sel_job_by_type = "";

        $(".chkJobType").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_type != "") {
              str_sel_job_by_type = str_sel_job_by_type + ",";
            }
            str_sel_job_by_type = str_sel_job_by_type + $(this).val();

          }

        });


        console.log("JOB BY TYPE SEL: ", str_sel_job_by_type);

        window.$cookies.set("filter_ads_job_by_type", str_sel_job_by_type, "9y");




        // job by career

        var str_sel_job_by_career = "";

        $(".chkJobCareer").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_career != "") {
              str_sel_job_by_career = str_sel_job_by_career + ",";
            }
            str_sel_job_by_career = str_sel_job_by_career + "'" + $(this).val() + "'";

          }

        });


        console.log("JOB BY CAREER SEL: ", str_sel_job_by_career);

        window.$cookies.set("filter_ads_job_by_career", str_sel_job_by_career, "9y");




        // job by degree

        var str_sel_job_by_degree = "";

        $(".chkJobDegree").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_degree != "") {
              str_sel_job_by_degree = str_sel_job_by_degree + ",";
            }
            str_sel_job_by_degree = str_sel_job_by_degree + $(this).val();

          }

        });


        console.log("JOB BY TYPE SEL: ", str_sel_job_by_degree);

        window.$cookies.set("filter_ads_job_by_degree", str_sel_job_by_degree, "9y");




        // job by insustry

        var str_sel_job_by_industry = "";

        $(".chkJobIndustry").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_industry != "") {
              str_sel_job_by_industry = str_sel_job_by_industry + ",";
            }
            str_sel_job_by_industry = str_sel_job_by_industry + $(this).val();

          }

        });

        console.log("JOB BY TYPE SEL: ", str_sel_job_by_industry);

        window.$cookies.set("filter_ads_job_by_industry", str_sel_job_by_industry, "9y");


        // job by func area

        var str_sel_job_by_func_area = "";

        $(".chkJobFuncArea").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_func_area != "") {
              str_sel_job_by_func_area = str_sel_job_by_func_area + ",";
            }
            str_sel_job_by_func_area = str_sel_job_by_func_area + $(this).val();

          }

        });

        console.log("JOB BY TYPE SEL: ", str_sel_job_by_func_area);

        window.$cookies.set("filter_ads_job_by_func_area", str_sel_job_by_func_area, "9y");



        // job by skill

        var str_sel_job_by_skill = "";

        $(".chkJobSkill").each(function (index) {

          console.log(index);

          if ($(this).prop("checked")) {

            if (str_sel_job_by_skill != "") {
              str_sel_job_by_skill = str_sel_job_by_skill + ",";
            }
            str_sel_job_by_skill = str_sel_job_by_skill + $(this).val();

          }

        });

        console.log("JOB BY TYPE SEL: ", str_sel_job_by_skill);

        window.$cookies.set("filter_ads_job_by_skill", str_sel_job_by_skill, "9y");









        router.push({ path: '/initAdsGrid' }).catch(() => { });


      });





      $('body').on('click', '#btnViewMoreJobsByTitle', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByTitle; x++) {

          $("#li_job_title_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByTitle").hide();

      });



      $('body').on('click', '#btnViewMoreJobsByCountry', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByCountry; x++) {

          $("#li_job_country_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByCountry").hide();

      });

      $('body').on('click', '#btnViewMoreJobsByState', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByState; x++) {

          $("#li_job_state_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByState").hide();

      });

      $('body').on('click', '#btnViewMoreJobsByCity', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByCity; x++) {

          $("#li_job_city_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByCity").hide();

      });

      $('body').on('click', '#btnViewMoreJobsByExp', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByExp; x++) {

          $("#li_job_exp_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByExp").hide();

      });


      $('body').on('click', '#btnViewMoreJobsByType', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByType; x++) {

          $("#li_job_type_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByType").hide();

      });


      $('body').on('click', '#btnViewMoreJobsByCareer', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByCareer; x++) {

          $("#li_job_career_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByCareer").hide();

      });


      $('body').on('click', '#btnViewMoreJobsByDegree', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByDegree; x++) {

          $("#li_job_degree_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByDegree").hide();

      });


      $('body').on('click', '#btnViewMoreJobsByIndustry', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByIndustry; x++) {

          $("#li_job_industry_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByIndustry").hide();

      });


      $('body').on('click', '#btnViewMoreJobsBySkill', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsBySkill; x++) {

          $("#li_job_skill_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsBySkill").hide();

      });


      $('body').on('click', '#btnViewMoreJobsByFunc', function (event) {
        event.preventDefault();

        console.log("VIEW MORE");

        for (var x = 3; x < pointerVue.numElementsFilterJobsByFunc; x++) {

          $("#li_job_func_" + x).css("display", "block");

        }

        $("#btnViewMoreJobsByFunc").hide();

      });








      $('body').on('click', '.clButtonRefresh', function (event) {
        event.preventDefault();

        router.push({ path: '/reloadPage/adsGrid' }).catch(() => { });


      });


      $('body').on('click', '.imgSearchJob', function (event) {
        event.preventDefault();

        console.log("IMG_SEARCH_JOB");

        var txt_search = $("#search").val();

        window.$cookies.set("filter_ads_txt_search", txt_search, "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_job_by_title", "", "9y");

        window.$cookies.set("filter_ads_job_by_state", "", "9y");

        window.$cookies.set("filter_ads_job_by_city", "", "9y");

        window.$cookies.set("filter_ads_job_by_exp", "", "9y");

        window.$cookies.set("filter_ads_job_by_type", "", "9y");

        window.$cookies.set("filter_ads_job_by_career", "", "9y");

        window.$cookies.set("filter_ads_job_by_degree", "", "9y");

        window.$cookies.set("filter_ads_job_by_industry", "", "9y");

        window.$cookies.set("filter_ads_job_by_func_area", "", "9y");

        window.$cookies.set("filter_ads_job_by_skill", "", "9y");

        router.push({ path: '/initAllJobs' }).catch(() => { });

      });







      $('body').on('click', '#btnHome', function (event) {
        event.preventDefault();

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        window.$cookies.set("filter_ads_job_by_title", "", "9y");

        window.$cookies.set("filter_ads_job_by_state", "", "9y");

        window.$cookies.set("filter_ads_job_by_city", "", "9y");

        window.$cookies.set("filter_ads_job_by_exp", "", "9y");

        window.$cookies.set("filter_ads_job_by_type", "", "9y");

        window.$cookies.set("filter_ads_job_by_career", "", "9y");

        window.$cookies.set("filter_ads_job_by_degree", "", "9y");

        window.$cookies.set("filter_ads_job_by_industry", "", "9y");

        window.$cookies.set("filter_ads_job_by_func_area", "", "9y");

        window.$cookies.set("filter_ads_job_by_skill", "", "9y");


        router.push({ path: '/searchAds' }).catch(() => { });

      });



      $('body').on('click', '#btnAllJobs', function (event) {
        event.preventDefault();

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        window.$cookies.set("filter_ads_job_by_title", "", "9y");

        window.$cookies.set("filter_ads_job_by_state", "", "9y");

        window.$cookies.set("filter_ads_job_by_city", "", "9y");

        window.$cookies.set("filter_ads_job_by_exp", "", "9y");

        window.$cookies.set("filter_ads_job_by_type", "", "9y");

        window.$cookies.set("filter_ads_job_by_career", "", "9y");

        window.$cookies.set("filter_ads_job_by_degree", "", "9y");

        window.$cookies.set("filter_ads_job_by_industry", "", "9y");

        window.$cookies.set("filter_ads_job_by_func_area", "", "9y");

        window.$cookies.set("filter_ads_job_by_skill", "", "9y");


        router.push({ path: '/initAllJobs' }).catch(() => { });

      });



      $('body').on('click', '.termOfUse', function (event) {

        console.log(event);

        console.log("TERM_OF_USE");


        if ($(this).prop("checked")) {


          $('#btnApply').show();

        }
        else {

          $('#btnApply').hide();
        }



      });





      $('body').on('click', '#openTermUse', function (event) {
        event.preventDefault();

        console.log("OPEN_TERM_OF_USE");

        $(".contpopuptermuse").show();

        pointerVue.$modal.show('popupTermUse');


        setTimeout(() => {

          $(".clPrivacy").html(pointerVue.txtTermOfUse);

        }, 200);


      });




      // $('body').on('click', '#btnApply', function (event) {
      //   event.preventDefault();

      //   console.log("BTN_APPLY");

      //   pointerVue.validate();

      // });





      $('body').on('click', '#gotToHome', function (event) {
        event.preventDefault();

        router.push({ path: '/searchAds' }).catch(() => { });


      });


      $('body').on('ionChange', '.idregione', function (event) {
        event.preventDefault();

        pointerVue.setComuni(this.value);


      });


      $('body').on('click', '#btnSignInCompany', function (event) {
        event.preventDefault();

        window.$cookies.set("page_go_back", "adsGrid", "9y");

        if (window.urlSiteCareers != "https://jobmule.eu/") {


          router.push({ path: '/loginAzienda' }).catch(() => { });

        }
        else {

          router.push({ path: '/loginAziendaJobmule' }).catch(() => { });

        }



      });




      $('body').on('click', '#btnSignInCandidate', function (event) {
        event.preventDefault();

        window.$cookies.set("page_go_back", "searchAds", "9y");


        if (window.urlSiteCareers != "https://jobmule.eu/") {

          router.push({ path: '/loginCandidati' }).catch(() => { });

        }
        else {

          router.push({ path: '/loginCandidatiJobmule' }).catch(() => { });

        }


      });








      $('body').on('click', '.goToHome', function (event) {
        event.preventDefault();

        router.push({ path: '/searchAds' }).catch(() => { });

      });




      $('body').on('click', '.viewAds', function (event) {
        event.preventDefault();

        var v_id = $(this).attr("data-id");

        console.log("VAL ID: ", v_id);

      });



    });



  },


  // data:

  name: "Login",
  data() {
    return {

      txtPrivacyHome: "",


      txtSearchKeywordsPlaceholder: "",


      txtPrivacy: "",

      numElementsFilterJobsByTitle: 0,
      numElementsFilterJobsByCountry: 0,
      numElementsFilterJobsByState: 0,
      numElementsFilterJobsByCity: 0,
      numElementsFilterJobsByExp: 0,
      numElementsFilterJobsByType: 0,
      numElementsFilterJobsByCareer: 0,
      numElementsFilterJobsByDegree: 0,
      numElementsFilterJobsByIndustry: 0,
      numElementsFilterJobsBySkill: 0,
      numElementsFilterJobsByFunc: 0,
      numElementsFilterJobsByCompany: 0,

      txtTermOfUse: "",

      widthTermOfUse: 0,

      valueOtpDelete: "",

      nameAziOverview: "",
      logoAziOverview: "",
      addressCompanyOverview: "",
      txtNumJobsOpen: "",
      urlSiteCompany: "",


      skillDetails: null,


      titleAds: "",
      datePosted: "",
      descLocation: "",
      tipoContratto: "",
      careerLevel: "",
      yearExperience: "",
      titoloStudio: "",
      adsNumber: "",
      textPoweredBy: "",
      applyBefore: "",
      descriptionJob: "",


      pathLogo: "",

      dialogRegister: true,

      notifications: false,
      sound: true,
      widgets: false,

      showSpinner: false,
      input: {
        username: "",
        password: "",
      },

      nameSearch: "",
      funcAreaSearch: "0",
      regioneSearch: "0",
      comuneSearch: "0",

      funcArea: [],
      regioni: [],
      comuni: [],
      comuniTemp: [],


      items: [],

      //items: [{ id: "tbAnalysysTools", name: "Analysys Tools" }],


      adsFuncArea: [],
      adsCities: [],
      adsIndustries: [],

      tab: null,


    };
  },
  methods: {


    btnConfirmPrivacyHome: function () {

      this.$modal.hide('popupPrivacyHome');

    },



    getWindowMobilePrivacyHome: function () {

      if (window.innerWidth <= 768) {

        return 350;

      }
      else {

        return 616;

      }

    },



    setUrlDomainGlobal: function () {

      let result = "";

      if (window.siteForTest == "") {

        if (location.hostname == "localhost") {
          result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

          result = location.protocol + "//" + location.hostname + "/";

        }

      }
      else {

        result = window.siteForTest;

      }



      window.urlSiteCareers = result;

    },


    btnConfirmTermOfUse: function () {

      this.$modal.hide('popupTermUse');


    },



    sendCV: async function () {



      var that = this;

      var id_ads = window.$cookies.get("id_ads_detail");

      console.log("FIRST NAME: ", $("#firstName").val());
      console.log("SURNAME: ", $("#surname").val());
      console.log("EMAIL: ", $("#email").val());
      console.log("TELEFONO: ", $("#telefono").val());
      console.log("ID_ADS: ", id_ads);
      console.log("ATTACHMENT: ", $('#attachment')[0].files[0]);

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apicareers.sendCV(
        $("#firstName").val(),
        $("#surname").val(),
        $("#email").val(),
        $("#telefono").val(),
        id_ads,
        $('#attachment')[0].files[0],
        7
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from sendCV", res);

        that.$swal({
          icon: "success",
          text: "CV inviato correttamente una mail di conferma Le è stata inviata",
          showConfirmButton: false,
          timer: 5000
        });

        $("#firstName").val("");
        $("#surname").val("");
        $("#email").val("");
        $("#telefono").val("");
        $("#attachment").val(null);
        $('.termOfUse').prop('checked', false);
        $('#btnApply').hide();


      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );




    },

    getWindowMobileTermOfUse: function () {

      if (window.innerWidth <= 768) {

        return 350;

      }
      else {

        return 616;

      }

    },


    btnConfirmPrivacy: function () {


      this.$modal.hide('popupPrivacy');

    },

    btnCancelOtpDelete: function () {

      this.$modal.hide('popupOtpDelete');

    },

    btnConfirmOtpDelete: function () {

      if (this.valueOtpDelete != "") {

        console.log("OTP INP: ", this.valueOtpDelete);
        console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

        if (window.myVal == btoa(this.valueOtpDelete)) {

          this.$modal.hide('popupOtpDelete');

          this.sendCV();


        }
        else {

          this.$swal({
            icon: "error",
            text: "Valore OTP non corretto",
            showConfirmButton: false,
            timer: 2000
          });


        }



      }
      else {

        this.$swal({
          icon: "error",
          text: "Specificare un  valore dell'OTP",
          showConfirmButton: false,
          timer: 2000
        });

      }


    },



    getOtpSendCV: async function () {

      var that = this;


      var ids = "";

      $(".val_status").each(function (index) {

        console.log(index);

        if ($(this).prop("checked")) {

          if (ids != "") {
            ids = ids + ",";
          }
          ids = ids + $(this).attr("data-id");
        }

      });

      console.log("IDS SEL:", ids);


      window.myVal = "";


      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apiotp.generateOTPAndSendMailSendCV(
        $("#email").val(),
        'careers',
        'send_cv',
        7
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from generateOTPAndSendMailSendCV", res);

        window.myVal = res.data.Result;


        setTimeout(() => {

          $(".contpopupotpdelete").show();

          that.valueOtpDelete = "";

          that.$modal.show('popupOtpDelete');


        }, 200);





      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );






    },


    validateEMail: function (v_email) {

      var result;

      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      console.log("MAIL: ", v_email);

      if (v_email != undefined) {

        if (v_email != "") {

          if (v_email.match(validRegex)) {
            result = true;
          }
          else {
            result = false;

          }


        }
        else {

          result = true;

        }


      }
      else {
        result = true;

      }


      return result;

    },

    validate: function () {

      var that = this;

      var errore = "";

      console.log("FIRST NAME: ", $("#firstName").val());
      console.log("SURNAME: ", $("#surname").val());
      console.log("EMAIL: ", $("#email").val());
      //     console.log("TERM OF USE: ", $(".termOfUse").is(":checked"));

      if ($("#firstName").val() == "") {
        errore = "First Name Required !";
      }

      if (errore == "") {
        if ($("#surname").val() == "") {
          errore = "Surname Required !";
        }
      }

      if (errore == "") {
        if ($("#email").val() == "") {
          errore = "E-Mail Required !";
        }
      }

      if (errore == "") {
        if ($("#email").val() != "") {

          if (!this.validateEMail($("#email").val())) {

            errore = "E-Mail Not Valid !";

          }

        }
      }


      if (errore == "") {

        if ($('#attachment')[0].files.length == 0) {

          errore = "Attachment Required !";

        }

      }

      if (errore == "") {

        if ($('#attachment')[0].files.length > 0) {

          var myfile = $('#attachment')[0].files[0];

          var lfile = myfile.name.split(".");

          console.log("MYFILE: ", lfile[lfile.length - 1]);

          var extension = lfile[lfile.length - 1]

          console.log("EXT: ", extension);

          if ((extension.toString().toUpperCase() != "PDF") && (extension.toString().toUpperCase() != "DOC") && (extension.toString().toUpperCase() != "DOCX")) {

            errore = "Accettati solo file pdf, doc, docx";

          }


        }

      }


      // if (errore == "") {

      //   if (!$("#termOfUse").is(":checked")) {

      //     errore = "Term Of Use Required !";


      //   }


      // }



      if (errore == "") {

        console.log("ok");

        that.getOtpSendCV();

      }
      else {

        that.$swal({
          icon: "error",
          text: errore,
          showConfirmButton: false,
          timer: 2000
        });


      }



    },


    getWindowMobile: function () {

      if (window.innerWidth <= 768) {

        return true;

      }
      else {

        return false;

      }

    },


    getWindowDesktop: function () {

      if (window.innerWidth > 768) {

        return true;

      }
      else {

        return false;

      }

    },


    manageTabs: function () {

      if (window.innerWidth <= 768) {

        $("#vTabs").removeClass("v-tabs--right");
        $("#vTabs").addClass("v-tabs--centered");

      }
      else {

        $("#vTabs").addClass("v-tabs--right");
        $("#vTabs").removeClass("v-tabs--centered");

      }
    },

    getClass() {

      console.log("WIND WID: ", window.innerWidth);

      if (window.innerWidth <= 768) {

        return 'center-active centered'
      }


      else {
        return 'right'
      }

    },

    changeTabOld: function (v_tab) {

      console.log("TAB:", v_tab);

      if (v_tab == "Functional Area") {


        setTimeout(() => {

          $("#tbFunctionalArea").removeClass("cl-tab");
          $("#tbFunctionalArea").addClass("cl-tab-select");

          $("#tbIndustries").removeClass("cl-tab-select");
          $("#tbIndustries").addClass("cl-tab");

          $("#tbCities").removeClass("cl-tab-select");
          $("#tbCities").addClass("cl-tab");


        }, 200);


      }



      if (v_tab == "Cities") {


        setTimeout(() => {

          $("#idTabCities").empty();

          // browse job by cities
          var v_tot_cities = this.adsCities;

          for (var x = 0; x < v_tot_cities.length; x++) {

            var txt = `<li class="col-md-4 col-sm-6"><a style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" title=""` + v_tot_cities[x].nome + `"">` + v_tot_cities[x].nome + ` <span>(` + v_tot_cities[x].v_tot + `)</span></a></li>`;

            $("#idTabCities").append(txt);


            $("#tbFunctionalArea").removeClass("cl-tab-select");
            $("#tbFunctionalArea").addClass("cl-tab");

            $("#tbIndustries").removeClass("cl-tab-select");
            $("#tbIndustries").addClass("cl-tab");

            $("#tbCities").removeClass("cl-tab");
            $("#tbCities").addClass("cl-tab-select");


          }


        }, 200);



      }

      if (v_tab == "Industries") {


        setTimeout(() => {

          $("#idTabIndustries").empty();

          // browse job by cities
          var v_tot_industries = this.adsIndustries;

          for (var x = 0; x < v_tot_industries.length; x++) {

            var txt = `<li class="col-md-4 col-sm-6"><a style="font-size:14px;font-family: 'Orbitron', sans-serif!important;" title="` + v_tot_industries[x].v_nome + `">` + v_tot_industries[x].v_nome + ` <span>(` + v_tot_industries[x].v_tot + `)</span></a></li>`;

            $("#idTabIndustries").append(txt);



            $("#tbFunctionalArea").removeClass("cl-tab-select");
            $("#tbFunctionalArea").addClass("cl-tab");

            $("#tbCities").removeClass("cl-tab-select");
            $("#tbCities").addClass("cl-tab");


            $("#tbIndustries").removeClass("cl-tab");
            $("#tbIndustries").addClass("cl-tab-select");


          }


        }, 200);



      }


    },



    setComuni: function (id_regione) {

      console.log("PROV SEL 2: ", id_regione);

      console.log("COMUNI TMP: ", this.comuniTemp);

      this.comuni = [];

      var Comuni = [];

      Comuni.push({
        idcomune: "0",
        idregione: "0",
        nome: "Select City"
      });

      for (var i = 0; i < this.comuniTemp.length; i++) {

        if (parseInt(this.comuniTemp[i].idregione) == parseInt(id_regione)) {

          Comuni.push({
            idcomune: this.comuniTemp[i].idcomune,
            idregione: this.comuniTemp[i].idregione,
            nome: this.comuniTemp[i].nome
          });

        }

      }

      console.log("COMUNI SEL: ", Comuni);

      this.comuni = Comuni;



    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    ,
    getCookie() {
      this.$cookies.set("cookie-consent-performance", "yes");

      //  this.$swal('You are logged in!!!');

      //   console.log(Vue.cookie.get('token'));

      // it gets the cookie called `username`
      /* const username = this.$cookies.get("username");
      console.log(username); */
    },


    changeTab: function (v_id_tab) {

      setTimeout(() => {


        console.log("ID TAB: ", v_id_tab);

        console.log("SKILL DET: ", this.skillDetails);


        var v_id = "#id" + v_id_tab;


        console.log("ID TAB2: ", v_id);


        $(v_id).empty();


        if (this.skillDetails != null) {


          for (var xx = 0; xx < this.skillDetails.length; xx++) {



            if (v_id_tab != "") {


              if (this.skillDetails[xx].idCat == v_id_tab) {


                $("#" + v_id_tab).removeClass("cl-tab");
                $("#" + v_id_tab).addClass("cl-tab-select");


                console.log("ELEMENTS: ", this.skillDetails[xx].elements);
                console.log("ID CAT: ", this.skillDetails[xx].idCat);


                for (var yy = 0; yy < this.skillDetails[xx].elements.length; yy++) {



                  var v_nome = this.skillDetails[xx].elements[yy].name;
                  var v_logo = "https://app.emtool.eu/public/img/skill/" + this.skillDetails[xx].elements[yy].logo;


                  var txt = "<li class='col-md-4 col-sm-6'><a onclick='return false;'' href='' title='" + v_nome + "' class='labelImgPreview'>" + v_nome + " <span>(1)</span></a><div class='imgPreview'><img src='" + v_logo + "' alt='' class='src'></div></li>";

                  console.log("ID TAB3: ", v_id);
                  console.log("TXT TAB3: ", txt);

                  $(v_id).append(txt);



                }






              }
              else {

                var v_id2 = "#" + this.skillDetails[xx].idCat;

                $(v_id2).removeClass("cl-tab-select");
                $(v_id2).addClass("cl-tab");


              }








            }







          }






        }


      }, 200);








    },


    getAdsDetails: async function () {

      var that = this;

      var id_ads = window.$cookies.get("id_ads_detail");

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apisearchads.getAdsDetails(
        id_ads
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from getAdsDetails", res);


        that.titleAds = res.data.Result.title;
        that.datePosted = res.data.data_enabled;
        that.descLocation = res.data.desc_location;
        that.tipoContratto = res.data.Result.desc_contratto;
        that.careerLevel = res.data.Result.level_work;
        that.yearExperience = res.data.Result.year_experience;
        that.titoloStudio = res.data.Result.desc_studi_minimi;
        that.applyBefore = res.data.apply_before;
        that.adsNumber = res.data.Result.number_ads;


        that.nameAziOverview = res.data.overview_cmp_name;
        that.logoAziOverview = res.data.overview_cmp_logo;

        that.urlSiteCompany = res.data.url_site_azi;

        that.addressCompanyOverview = res.data.str_addr_cmp;

        that.txtNumJobsOpen = res.data.txt_num_jobs;



        that.items = res.data.query_skill_category;

        that.skillDetails = res.data.query_skill_details;



        $("#divTxtAzienda").html(res.data.azienda_overview);





        $(".divJobDesc").html(res.data.Result.description);

        setTimeout(() => {

          if (res.data.idFirstCatSkill != "") {

            $("#" + res.data.idFirstCatSkill).removeClass("cl-tab");
            $("#" + res.data.idFirstCatSkill).addClass("cl-tab-select");

          }


          if (res.data.query_skill_details != null) {


            for (var xx = 0; xx < res.data.query_skill_details.length; xx++) {



              if (res.data.idFirstCatSkill != "") {


                if (res.data.query_skill_details[xx].idCat == res.data.idFirstCatSkill) {




                  console.log("ELEMENTS: ", res.data.query_skill_details[xx].elements);
                  console.log("ID CAT: ", res.data.query_skill_details[xx].idCat);


                  for (var yy = 0; yy < res.data.query_skill_details[xx].elements.length; yy++) {



                    var v_nome = res.data.query_skill_details[xx].elements[yy].name;
                    var v_logo = "https://app.emtool.eu/public/img/skill/" + res.data.query_skill_details[xx].elements[yy].logo;


                    var txt = "<li class='col-md-4 col-sm-6'><a onclick='return false;'' href='' title='" + v_nome + "' class='labelImgPreview'>" + v_nome + " <span>(1)</span></a><div class='imgPreview'><img src='" + v_logo + "' alt='' class='src'></div></li>";


                    var v_id = "#id" + res.data.query_skill_details[xx].idCat;

                    $(v_id).append(txt);



                  }






                }








              }







            }






          }




        }, 200);












      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );




    },





    searchJobs: async function () {

      var that = this;

      var filter_ads_featured = window.$cookies.get("filter_ads_featured");
      var num_latest_jobs = window.$cookies.get("num_latest_jobs");
      var filter_ads_id_sub_cat = window.$cookies.get("filter_ads_id_sub_cat");
      var filter_ads_id_city = window.$cookies.get("filter_ads_id_city");
      var filter_ads_id_cat = window.$cookies.get("filter_ads_id_cat");
      var filter_ads_txt_search = window.$cookies.get("filter_ads_txt_search");
      var filter_ads_id_regione = window.$cookies.get("filter_ads_id_regione");
      var filter_ads_id_comune = window.$cookies.get("filter_ads_id_comune");
      var filter_ads_job_by_title = window.$cookies.get("filter_ads_job_by_title");
      var filter_ads_job_by_state = window.$cookies.get("filter_ads_job_by_state");
      var filter_ads_job_by_city = window.$cookies.get("filter_ads_job_by_city");
      var filter_ads_job_by_exp = window.$cookies.get("filter_ads_job_by_exp");
      var filter_ads_job_by_type = window.$cookies.get("filter_ads_job_by_type");
      var filter_ads_job_by_career = window.$cookies.get("filter_ads_job_by_career");
      var filter_ads_job_by_degree = window.$cookies.get("filter_ads_job_by_degree");
      var filter_ads_job_by_industry = window.$cookies.get("filter_ads_job_by_industry");
      var filter_ads_job_by_func_area = window.$cookies.get("filter_ads_job_by_func_area");
      var filter_ads_job_by_skill = window.$cookies.get("filter_ads_job_by_skill");


      console.log("FILTER FEATURED: ", filter_ads_featured);

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apicareers.searchJobs(
        window.urlSiteCareers,
        filter_ads_featured,
        num_latest_jobs,
        filter_ads_id_sub_cat,
        filter_ads_id_city,
        filter_ads_id_cat,
        filter_ads_txt_search,
        filter_ads_id_regione,
        filter_ads_id_comune,
        filter_ads_job_by_title,
        filter_ads_job_by_state,
        filter_ads_job_by_city,
        filter_ads_job_by_exp,
        filter_ads_job_by_type,
        filter_ads_job_by_career,
        filter_ads_job_by_degree,
        filter_ads_job_by_industry,
        filter_ads_job_by_func_area,
        filter_ads_job_by_skill
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from searchJobs", res);


        if (res.data.Errors == "OK") {

          $("#divSearchResult").html(res.data.Result);

          $(".showreslt").html(res.data.TxtShowPages);



          // left filters jobs by title

          that.numElementsFilterJobsByTitle = res.data.count_job_title;

          $("#divJobsByTitle").html(res.data.html_filter_job_title);

          if (res.data.count_job_title <= 3) {

            $("#btnViewMoreJobsByTitle").hide();

          }
          else {


            for (var x = 3; x < res.data.count_job_title; x++) {

              $("#li_job_title_" + x).css("display", "none");


            }


          }


          // left filters jobs by country

          that.numElementsFilterJobsByCountry = res.data.count_job_country;

          $("#divJobsByCountry").html(res.data.html_filter_job_country);

          if (res.data.count_job_country <= 3) {

            $("#btnViewMoreJobsByCountry").hide();

          }
          else {


            for (var x2 = 3; x2 < res.data.count_job_country; x2++) {

              $("#li_job_country_" + x2).css("display", "none");


            }


          }



          // left filters jobs by state

          that.numElementsFilterJobsByState = res.data.count_job_state;

          $("#divJobsByState").html(res.data.html_filter_job_state);

          if (res.data.count_job_state <= 3) {

            $("#btnViewMoreJobsByState").hide();

          }
          else {


            for (var x3 = 3; x3 < res.data.count_job_state; x3++) {

              $("#li_job_state_" + x3).css("display", "none");


            }


          }



          // left filters jobs by city

          that.numElementsFilterJobsByCity = res.data.count_job_city;

          $("#divJobsByCity").html(res.data.html_filter_job_city);

          if (res.data.count_job_city <= 3) {

            $("#btnViewMoreJobsByCity").hide();

          }
          else {


            for (var x4 = 3; x4 < res.data.count_job_city; x4++) {

              $("#li_job_city_" + x4).css("display", "none");


            }


          }




          // left filters jobs by experience

          that.numElementsFilterJobsByExp = res.data.count_job_exp;

          $("#divJobsByExp").html(res.data.html_filter_job_exp);

          if (res.data.count_job_exp <= 3) {

            $("#btnViewMoreJobsByExp").hide();

          }
          else {


            for (var x5 = 3; x5 < res.data.count_job_exp; x5++) {

              $("#li_job_exp_" + x5).css("display", "none");


            }


          }




          // left filters jobs by type

          that.numElementsFilterJobsByType = res.data.count_job_type;

          $("#divJobsByType").html(res.data.html_filter_job_type);

          if (res.data.count_job_type <= 3) {

            $("#btnViewMoreJobsByType").hide();

          }
          else {


            for (var x6 = 3; x6 < res.data.count_job_type; x6++) {

              $("#li_job_type_" + x6).css("display", "none");


            }


          }



          // left filters jobs by career

          that.numElementsFilterJobsByCareer = res.data.count_job_career;

          $("#divJobsByCareer").html(res.data.html_filter_job_career);

          if (res.data.count_job_career <= 3) {

            $("#btnViewMoreJobsByCareer").hide();

          }
          else {


            for (var x7 = 3; x7 < res.data.count_job_career; x7++) {

              $("#li_job_career_" + x7).css("display", "none");


            }


          }


          // left filters jobs by degree

          that.numElementsFilterJobsByDegree = res.data.count_job_degree;

          $("#divJobsByDegree").html(res.data.html_filter_job_degree);

          if (res.data.count_job_degree <= 3) {

            $("#btnViewMoreJobsByDegree").hide();

          }
          else {


            for (var x8 = 3; x8 < res.data.count_job_degree; x8++) {

              $("#li_job_degree_" + x8).css("display", "none");


            }


          }



          // left filters jobs by industry

          that.numElementsFilterJobsByIndustry = res.data.count_job_industry;

          $("#divJobsByIndustry").html(res.data.html_filter_job_industry);

          if (res.data.count_job_industry <= 3) {

            $("#btnViewMoreJobsByIndustry").hide();

          }
          else {


            for (var x9 = 3; x9 < res.data.count_job_industry; x9++) {

              $("#li_job_industry_" + x9).css("display", "none");


            }


          }




          // left filters jobs by skill

          that.numElementsFilterJobsBySkill = res.data.count_job_skill;

          $("#divJobsBySkill").html(res.data.html_filter_job_skill);

          if (res.data.count_job_skill <= 3) {

            $("#btnViewMoreJobsBySkill").hide();

          }
          else {


            for (var x10 = 3; x10 < res.data.count_job_skill; x10++) {

              $("#li_job_skill_" + x10).css("display", "none");


            }


          }



          // left filters jobs by function area

          that.numElementsFilterJobsByFunc = res.data.count_job_func;

          $("#divJobsByFunc").html(res.data.html_filter_job_func);

          if (res.data.count_job_func <= 3) {

            $("#btnViewMoreJobsByFunc").hide();

          }
          else {


            for (var x11 = 3; x11 < res.data.count_job_func; x11++) {

              $("#li_job_func_" + x11).css("display", "none");


            }


          }



          // left filters jobs by company

          that.numElementsFilterJobsByCompany = res.data.count_job_company;

          $("#divJobsByCompany").html(res.data.html_filter_job_company);

          if (res.data.count_job_company <= 3) {

            $("#btnViewMoreJobsByCompany").hide();

          }
          else {


            for (var x12 = 3; x12 < res.data.count_job_company; x12++) {

              $("#li_job_company_" + x12).css("display", "none");


            }


          }





          setTimeout(() => {

            // select checkbox current selected

            // job by title

            if (filter_ads_job_by_title != null) {
              if (filter_ads_job_by_title != "null") {
                if (filter_ads_job_by_title != "") {
                  var array_title_sel = filter_ads_job_by_title.split(",");
                  $(".chkJobTitle").each(function (index) {
                    console.log(index);
                    for (var x1 = 0; x1 < array_title_sel.length; x1++) {
                      if ($(this).val() == array_title_sel[x1]) {
                        $(this).prop("checked", true);
                      }
                    }
                  });
                }
              }
            }

            // job by region

            if (filter_ads_job_by_state != null) {
              if (filter_ads_job_by_state != "null") {
                if (filter_ads_job_by_state != "") {
                  var array_state_sel = filter_ads_job_by_state.split(",");
                  $(".chkJobState").each(function (index) {
                    console.log(index);
                    for (var x2 = 0; x2 < array_state_sel.length; x2++) {
                      if ($(this).val() == array_state_sel[x2]) {
                        $(this).prop("checked", true);
                      }
                    }
                  });
                }
              }
            }

            // job by city

            if (filter_ads_job_by_city != null) {
              if (filter_ads_job_by_city != "null") {
                if (filter_ads_job_by_city != "") {
                  var array_city_sel = filter_ads_job_by_city.split(",");
                  $(".chkJobCity").each(function (index) {
                    console.log(index);
                    for (var x3 = 0; x3 < array_city_sel.length; x3++) {
                      if ($(this).val() == array_city_sel[x3]) {
                        $(this).prop("checked", true);
                      }
                    }
                  });
                }
              }
            }

            // job by industry

            if (filter_ads_job_by_industry != null) {
              if (filter_ads_job_by_industry != "null") {
                if (filter_ads_job_by_industry != "") {
                  var array_industry_sel = filter_ads_job_by_industry.split(",");
                  $(".chkJobIndustry").each(function (index) {
                    console.log(index);
                    for (var x4 = 0; x4 < array_industry_sel.length; x4++) {
                      if ($(this).val() == array_industry_sel[x4]) {
                        $(this).prop("checked", true);
                      }
                    }
                  });
                }
              }
            }


            // job by skill

            if (filter_ads_job_by_skill != null) {
              if (filter_ads_job_by_skill != "null") {
                if (filter_ads_job_by_skill != "") {
                  var array_skill_sel = filter_ads_job_by_skill.split(",");
                  $(".chkJobSkill").each(function (index) {
                    console.log(index);
                    for (var x5 = 0; x5 < array_skill_sel.length; x5++) {
                      if ($(this).val() == array_skill_sel[x5]) {
                        $(this).prop("checked", true);
                      }
                    }
                  });
                }
              }
            }

            // job by functional area

            if (filter_ads_job_by_func_area != null) {
              if (filter_ads_job_by_func_area != "null") {
                if (filter_ads_job_by_func_area != "") {
                  var array_func_area_sel = filter_ads_job_by_func_area.split(",");
                  $(".chkJobFuncArea").each(function (index) {
                    console.log(index);
                    for (var x6 = 0; x6 < array_func_area_sel.length; x6++) {
                      if ($(this).val() == array_func_area_sel[x6]) {
                        $(this).prop("checked", true);
                      }
                    }
                  });
                }
              }
            }






          }, 200);

        }
        else {

          that.$router.push({
            path: "/searchAds",
          });



        }

















      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);

        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

        that.$router.push({
          path: "/searchAds",
        });

        // var msg = err.response.data.Error;

        // that.$swal({
        //   icon: "error",
        //   text: msg,
        //   showConfirmButton: false,
        //   timer: 8000
        // });


      }

      );




    },








    getValuesSearch: async function () {

      var that = this;

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apisearchads.getValuesSearch(
        window.urlSiteCareers
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from getValuesSearch", res);

        that.funcArea = res.data.funcArea;
        that.regioni = res.data.regioni;
        that.comuniTemp = res.data.comuni;

        that.funcAreaSearch = "0";
        that.regioneSearch = "0";

        that.pathLogo = res.data.file_logo;


        window.$cookies.set("url_logo_conf", res.data.file_logo, "9y");


        that.adsCities = res.data.query_tot_cities;

        that.adsIndustries = res.data.query_tot_industries;

        that.txtPrivacyHome = res.data.txt_privacy_home;


        that.txtPrivacy = res.data.txt_privacy;

        that.txtTermOfUse = res.data.txt_term_of_use;

        // browse job by functional area
        var v_tot_func_area = res.data.query_tot_func_area;

        for (var x = 0; x < v_tot_func_area.length; x++) {

          var txt = `<li class="col-md-4 col-sm-6"><a style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" title=""` + v_tot_func_area[x].description + `"">` + v_tot_func_area[x].description + ` <span>(` + v_tot_func_area[x].v_tot + `)</span></a></li>`;

          $("#idTabFyncArea").append(txt);

        }


        // quick links jobs by functional area
        for (var x2 = 0; x2 < v_tot_func_area.length; x2++) {

          if (x2 <= 2) {

            var txt2 = `<li><a class='clFuncArea' style="font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_func_area[x2].idsubcategory + `' title=""` + v_tot_func_area[x2].description + `"">` + v_tot_func_area[x2].description + `</a></li>`;

            $("#jobsByFuncAreaQuickLinks").append(txt2);

            console.log("ok");


          }


        }



        // quick links jobs by industries

        var v_tot_industries = that.adsIndustries;

        for (var x3 = 0; x3 < v_tot_industries.length; x3++) {

          var txt3 = `<li><a class='clIndustries' style="font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_industries[x3].idcategory + `' title=""` + v_tot_industries[x3].v_nome + `"">` + v_tot_industries[x3].v_nome + `</a></li>`;




          $("#jobsByIndustryQuickLinks").append(txt3);




        }







        $("#tbFunctionalArea").removeClass("cl-tab");
        $("#tbFunctionalArea").addClass("cl-tab-select");

        $(".bttxt").html(res.data.txt_powered_by);
        $(".txtInfoCompany").html(res.data.txt_azi_dx_footer);

        // immagine sfondo header
        if (res.data.file_img_sfondo_header != null) {

          if (res.data.file_img_sfondo_header != "") {

            var val_css = "url('" + res.data.file_img_sfondo_header + "')";
            var prop_css = "background";

            $(".searchwrap").css(prop_css, val_css);

          }

        }


        // immagine sfondo parallax

        $(".userloginbox").css("background", "url('')");

        if (res.data.file_img_sfondo_parallax != null) {

          if (res.data.file_img_sfondo_parallax != "") {

            var val_css2 = "url('" + res.data.file_img_sfondo_parallax + "') no-repeat center";
            var prop_css2 = "background";

            $(".userloginbox").css(prop_css2, val_css2);

            $(".userloginbox").css("background-attachment", "fixed");

            $(".userloginbox").css("background-size", "cover");

            $(".userloginbox").css("padding", "40px 0 120px 0");

            $(".userloginbox").css("text-align", "center");



          }


        }



        // featured job
        $("#divContFeaturedJobs").html(res.data.html_feat_job);
        $("#divContLatestJobs").html(res.data.html_latest_job);


        // immagine sfondo header pagina

        if (res.data.file_img_header_editads != null) {

          if (res.data.file_img_header_editads != "") {


            var val_css3 = "url('" + res.data.file_img_header_editads + "')";
            var prop_css3 = "background-image";

            $(".pageTitle").css(prop_css3, val_css3);

            $(".pageTitle").css("background-size", "cover");

            $(".pageTitle").css("background-repeat", "no-repeat");

            $(".pageTitle").css("background-position", "center");


            // var val_css3 = "url('" + res.data.file_img_header_editads + "')";
            // var prop_css3 = "background";

            // $(".pageTitle").css(prop_css3, val_css3);

            // $(".pageTitle").css("background-size", "cover");

            // $(".pageTitle").css("padding", "35px 0");



            // if (window.innerWidth <= 768) {

            //   $(".pageTitle").css("min-height", "229px");

            //   $(".pageTitle").css("background-position", "15px -65px");

            // }
            // else {

            //   $(".pageTitle").css("min-height", "281px");

            //   $(".pageTitle").css("background-position", "-1px -812px");

            // }




          }

        }






      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );




    },

    registerAction: async function () {
      //const auth = { user: this.username, password: this.password };
      // Correct username is 'foo' and password is 'bar'
      //const url = "https://app.emtool.eu/api/emt/register";

      const url = "https://app.emtool.eu/registerUserForCompanyPost";

      //const url = "http://emtool.local/registerUserForCompanyPost";


      this.success = false;
      this.error = null;

      let formData = new FormData();

      formData.append("context", "2recruit");

      if (this.input.username != undefined)
        formData.append("login", this.input.username);

      if (this.input.password != undefined)
        formData.append("password", this.input.password);

      formData.append("companyname", "7");

      if (this.input.name != undefined)
        formData.append("name", this.input.name);

      if (this.input.surname != undefined)
        formData.append("surname", this.input.surname);

      if (this.input.email != undefined)
        formData.append("email", this.input.email);

      if (this.input.phone != undefined)
        formData.append("phone", this.input.phone);

      // if (this.input.namecompany != undefined)
      //   formData.append("namecompany", this.input.namecompany);

      formData.append("app", "2recruit");

      formData.append("is_demo", "S");



      try {
        await this.axios
          .post(url, formData, { "content-type": "text/json" })
          .then((result) => {
            var respo = result.data;
            this.success = true;
            // console.log( respo   );

            setTimeout(() => {
              this.showSpinner = false;

              var msgError = "";
              console.log("re", respo.errors);



              if (respo.errors && !this.isEmpty(respo.errors)) {
                console.log("Errori", respo.errors);
                var err = respo.errors;

                Object.entries(err).forEach(([key, value]) => {
                  console.log(`${key} ${value}`);
                  msgError += `${value}\r\n `;
                });

                if (msgError) {
                  this.$swal({ icon: "error", text: msgError });

                }


              }
              else {
                this.$swal({
                  icon: "success",
                  text: "Please check your email",
                  confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_jobmule.png' />",
                });

                this.$router.push({
                  path: "/",
                });


              }
              //this.$swal("You are logged in!!!");

              /*     window.$cookies.set("token", respo.token , "9y");
                  window.$cookies.set("system", respo.system,"9y");
                  window.$cookies.set("token_system", respo.token_system,"9y");
                  window.$cookies.set("username", respo.username,"9y");
                  window.$cookies.set("user_id", respo.user_id,"9y");
                  window.$cookies.set("namesurname", respo.namesurname,"9y"); */

              this.$root.$children[0].curDipTop = respo.namesurname;

              //router.push({ path: "/" });

            }, 1);
          });
      } catch (err) {
        this.success = false;
        console.log(err.message);
        this.$swal({ icon: "error", text: "Please check your data" });

        this.showSpinner = false;
        //  console.log(   response);

        //   this.error = err.message;
      }
    },

    register() {
      this.showSpinner = true;
      this.getCookie();

      this.registerAction();
      /*     if (this.input.username != "" && this.input.password != "") {
        if (
          this.input.username == this.$parent.mockAccount.username &&
          this.input.password == this.$parent.mockAccount.password
        ) {
          this.$emit("authenticated", true);
          this.$router.replace({ name: "secure" });
        } else {
          console.log("The username and / or password is incorrect");
        }
      } else {
        console.log("A username and password must be present");
      } */
    },
  },
};
</script>


<style>
.ads-grid .clPipe {
  border: 1px solid rgb(102, 102, 102);
  height: 30px;
  margin-top: 20px;
}



.ads-grid .optionlist li input[type=checkbox]+label {
  width: 15px !important;
  height: 15px !important;
  border: 1px solid #e0e0e0 !important;
  background: #f5f5f5 !important;
  cursor: pointer !important;
  position: absolute !important;
  left: 0 !important;
  top: 8px !important;
}

.ads-grid .optionlist li input[type=checkbox]:checked+label:before {
  content: '\f00c' !important;
  font-family: 'FontAwesome' !important;
  color: #4ece00 !important;
  padding-left: 0px !important;
  position: relative !important;
  top: -3px !important;
}


.optionlist li span {
  position: absolute !important;
  right: 0 !important;
  top: 6px !important;
  font-family: 'Orbitron', sans-serif !important;
}

.ads-grid .clButtonHeader {
  width: 60px !important;
  margin-top: -9px !important;
}

.ads-grid .clButtonJobAll {
  width: 31px !important;
  margin-top: 2px !important;
}

.ads-grid .clButtonRefresh {
  width: 31px !important;
  margin-top: 2px !important;
}


.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}

.ads-grid .clHeadAds {
  display: flex !important;
  align-items: center !important;
}

.ads-grid .colImgViewDetails {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.ads-grid .imgViewDetail {
  width: 40px;
}


.searchList li .location {
  color: #000 !important;
  font-family: 'Orbitron', sans-serif !important;

}

.fulltime {
  background: #6ACA00 !important;
  color: #fff !important;
  font-size: 13px !important;
  display: inline-block !important;
  padding: 4px 6px !important;
  border-radius: 3px !important;
  font-family: 'Orbitron', sans-serif !important;
}

.searchList li .location span {
  color: #333 !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;

}

.searchList li .companyName {
  margin: 10px 0 !important;
  color: #969595 !important;
  font-family: 'Orbitron', sans-serif !important;
  padding-top: 0px !important;
  margin-top: 0px !important;

}

.searchList li p {
  line-height: 22px !important;
  color: #333 !important;
  margin: 10px 0 0 0 !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;

}

.searchList li .listbtn {
  margin-top: 20px !important;
}

.pagiWrap .showreslt {
  font-weight: 600 !important;
  margin-top: 10px !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.searchList li .listbtn a {
  background: #fff !important;
  display: block !important;
  border-radius: 40px !important;
  width: 100% !important;
  color: #444 !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  padding: 10px 15px !important;
  text-align: center !important;
  border: 1px solid #444 !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;

}

.searchList li .companyName a {
  color: #969595 !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.searchList li .jobimg {
  min-height: 52px !important;
  min-width: 100px !important;
}

.searchList li .jobimg img {
  min-width: 126px !important;
}


.searchList li h3 a {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000 !important;
  font-family: 'Orbitron', sans-serif !important;

}

.searchnt .btn {
  background: #444 !important;
  display: block !important;
  border-radius: 0 !important;
  width: 100% !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  padding: 10px 15px !important;
  font-family: 'Orbitron', sans-serif !important;

}

.ads-grid .view_more {
  cursor: pointer !important;
  font-family: 'Orbitron', sans-serif !important;

}

.ads-grid .text-primary {
  color: #007bff !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.jobreqbtn a.btn-job-alert-disabled {
  color: #999 !important;
  border: 1px solid #999 !important;
  cursor: default !important;
  background: none !important;
  font-family: 'Orbitron', sans-serif !important;
}

.jobreqbtn a {
  display: block !important;
  padding: 10px 20px !important;
  text-align: left !important;
  margin-bottom: 10px !important;
  background: #444 !important;
  color: #fff !important;
  font-family: 'Orbitron', sans-serif !important;
}

.sidebar .widget-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #eb5c90 !important;
  margin-bottom: 20px !important;
  font-family: 'Orbitron', sans-serif !important;
}

.optionlist li {
  margin-bottom: 10px !important;
  padding: 5px 0 !important;
  position: relative !important;
  padding-left: 30px !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.clVTabs {}

.labelImgPreview {
  display: block !important;
  margin: 10px 0 !important;
  position: relative !important;
  padding-left: 15px !important;
  color: #000 !important;
  font-weight: 600 !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 12px !important;
}

.imgPreview {
  width: 68px !important;
  height: 68px !important;
  display: flex !important;
  border: 1px solid #d3d2d6 !important;
  justify-content: center !important;
  align-items: center !important;
}

.imgPreview img {
  max-width: 65px !important;
  max-height: 65px !important;
  padding: 2px !important;
}

.clPrivacy {
  width: 100%;
  overflow-y: auto;
  min-height: 391px;
  max-height: 391px;
  margin-left: 5px;
  margin-top: 5px;
  padding-right: 17px;
}


.clPrivacy h2 {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacy p {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacy strong {
  font-family: 'Orbitron', sans-serif !important;
  font-weight: bold;
}


.divJobDesc strong {
  font-family: Orbitron !important;
}


.list-default li:before {
  content: '' !important;
}


.job-header div,
.job-header span {
  font-family: Orbitron !important;
  font-size: 14px !important;
}

.detailsJob {
  font-size: 14px;
}


.cl-fj-5 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 10px !important;
}

.cl-fj-6 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #000 !important;
}


.cl-fj-1 {
  font-family: 'Orbitron', sans-serif !important;
}

.cl-fj-2 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.cl-fj-3 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.cl-portale-1 {
  font-family: 'Orbitron', sans-serif !important;
  color: white !important;
}


.howlist h4 {
  font-family: 'Orbitron', sans-serif !important;
}

.jobslist li .company {
  color: #999;
  padding: 7px 0;
  padding-top: 0px !important;
}

.titleTop h3 {
  font-family: 'Orbitron', sans-serif !important;
}

.titleTop span {
  font-family: 'Orbitron', sans-serif !important;
}

.navbar-nav {
  flex-direction: inherit !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  list-style: none !important;
}


.bttxt {
  color: #888 !important;
  line-height: 24px !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
}

.bttxt a {
  color: #888 !important;
  font-weight: 600 !important;
}

.address {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.email a {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.phone a {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.footerWrap .email:before {
  position: absolute !important;
  left: 0 !important;
  content: '\f003' !important;
  font-family: 'FontAwesome' !important;
  font-size: 18px !important;
}

ion-select {
  font-size: 18px !important;
  --placeholder-opacity: 1 !important;
  font-family: 'Orbitron', sans-serif !important;
}

.catelist li a:before {
  position: absolute !important;
  left: 0 !important;
  content: '\f0da' !important;
  font-family: 'FontAwesome' !important;
  font-weight: 400 !important;
}

.howlist li .iconcircle {
  text-align: center !important;
  margin-bottom: 15px !important;
  display: inline-block !important;
}

p,
h1,
h2,
h3,
h4,
h5,
body,
.job-header .contentbox p {
  font-family: 'Orbitron', sans-serif !important;
}

.howlist li .iconcircle i {
  font-size: 48px !important;
  color: #444 !important;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.job-header .contentbox h3 i,
.jobdetail h3 i,
.companyinfo h3 i {
  color: #ccc !important;
  margin-right: 5px !important;
  font-size: 20px !important;
}

.fa-user:before {
  content: '\f007' !important;
}

.fa-file-text-o:before {
  content: "\f0f6" !important;
  font-family: 'FontAwesome' !important;
}

.clReadMore {
  font-family: 'Orbitron', sans-serif !important;

}

button {
  font-family: 'Orbitron', sans-serif !important;
}

.select-ios .select-placeholder {
  color: #000;
  font-size: 15px;
  font-family: 'Orbitron', sans-serif !important;
}

.idsubcategory {
  min-width: 521px;
}

.idregione {
  min-width: 225px;
}

.companyid {
  min-width: 223px;
}

.ads-grid .cl-tab-select {
  font-size: 16px !important;
  background: #444 !important;
  color: white !important;
  font-family: 'Orbitron', sans-serif !important;
  font-weight: 700 !important;
  padding: 15px 30px !important;
  border-radius: 5px 5px 0 0 !important;
  border-color: #444 !important;
}


.clPrivacyHome {
  width: 100%;
  overflow-y: auto;
  min-height: 391px;
  max-height: 391px;
  margin-left: 5px;
  margin-top: 5px;
  padding-right: 17px;
}


.clPrivacyHome h2 {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacyHome p {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacyHome strong {
  font-family: 'Orbitron', sans-serif !important;
  font-weight: bold;
}


.ads-grid .cl-tab {
  font-size: 16px !important;
  background: none !important;
  color: #666 !important;
  font-family: 'Orbitron', sans-serif !important;
  font-weight: 400 !important;
  padding: 15px 30px !important;
}

.cl-title-jobsby {}

.v-overflow-bt-padding {
  padding-bottom: 6px !important
}

.liJobsMobile {
  display: none;
}

@media screen and (max-width: 768px) {

  .searchwrap {
    margin-top: 27px !important;
  }

  .idsubcategory {
    min-width: 278px;
  }

  .idregione {
    min-width: 278px;
  }

  .companyid {
    min-width: 278px;
  }


  .ads-grid .cl-tab {
    font-size: 10px !important;
    background: none !important;
    color: #666 !important;
    padding: 8px !important;
    width: 231px !important;
    min-width: 231px !important;
    font-family: 'Orbitron', sans-serif !important;
  }

  .ads-grid .cl-tab-select {
    font-size: 10px !important;
    background: #444 !important;
    color: white !important;
    padding: 8px !important;
    width: 231px !important;
    min-width: 231px !important;
    font-family: 'Orbitron', sans-serif !important;
  }

  .cl-title-jobsby {
    height: 108px !important;
  }

  .v-overflow-bt-padding {
    padding-bottom: 40px !important
  }

  .bttxt {
    color: #888 !important;
    line-height: 24px !important;
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
    display: block;
    align-items: center;
  }

  .bttxt a {
    color: #888 !important;
    font-weight: 600 !important;
  }

  .clPrivacy {
    width: 100%;
    overflow-y: auto;
    min-height: 391px;
    max-height: 391px;
    margin-left: 5px;
    margin-top: 5px;
    padding-right: 17px;
  }

  .clVTabs {
    min-width: 335px !important;
    margin-left: -32px !important;
  }

  .ads-grid .clHeadAds {
    display: block !important;

  }

  .ads-grid .clButtonHeader {
    min-width: 40px !important;
    margin-top: -6px !important;
  }

  .ads-grid .clButtonRefresh {
    min-width: 20px !important;
    margin-top: 1px !important;
  }

  .ads-grid .clPipe {
    border: 1px solid rgb(102, 102, 102);
    height: 17px;
    margin-top: 18px;
  }

  .ads-grid .clButtonJobAll {
    min-width: 20px !important;
    margin-top: 1px !important;
  }


  .liJobsNoMobile {
    display: none;
  }

  .liJobsMobile {
    display: block;
  }



}

.sidebar .widget-title {
  color: #999 !important;
}
</style>

<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
  display: none;
}

#login {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  /*   margin-top: 200px; */
  padding: 20px;
}

#nav,
.v-sheet {
  display: none;
}
</style>