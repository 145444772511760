<template>
  <div class="v-overflow v-overflow-bt-padding ads-search">



    <!-- start section header -->

    <div class="header"
      style="    position: fixed;
                                                                                                                                                                                                          min-width: 100vw;
                                                                                                                                                                                                          min-height: 54px;
                                                                                                                                                                                                          background-color: white;
                                                                                                                                                                                                          z-index: 100;">
      <div class="container-fluid">

        <div class="row">

          <div class="col-lg-2 col-md-6 col-6" style="display:flex;align-items:center;">


            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon id="no-background-hover" to="/initSearchAds" style="margin-left: 27px;">

                  <v-img :src="pathLogo" style="width:100px" />

                </v-btn>
              </template>

            </v-tooltip>


          </div>

          <div class="col-lg-10 col-md-6 col-6" id="divHeadBtns">

            <ul style="display:flex;justify-content:end;" class="navbar-nav">


              <li class="nav-item">
                <a id="btnAllJobs" class="nav-link">
                  <img src="@/assets/all_jobs.jpg" class="clButtonHeader" title="Jobs" />
                </a>
              </li>

              <li class="nav-item">
                <div class="clPipe"></div>
              </li>

              <li class="nav-item">
                <a id="btnSignInCompany" class="nav-link">
                  <img src="@/assets/login_aziende.jpg" class="clButtonHeader" title="Login Aziende" />
                </a>
              </li>

              <li class="nav-item">
                <a id="btnSignInCandidate" class="nav-link">
                  <img src="@/assets/login_candidati.jpg" class="clButtonHeader" title="Login Candidati" />
                </a>
              </li>

            </ul>

          </div>

          <div class="col-lg-10 col-md-6 col-6" id="divHeadBtnsJobmule">

            <ul style="display:flex;justify-content:end;" class="navbar-nav">


              <li class="nav-item">
                <a id="btnAllJobs" class="nav-link">
                  <img src="@/assets/all_jobs_jobmule_home.jpg" class="clButtonHeader" title="Jobs" />
                </a>
              </li>

              <li class="nav-item">
                <div class="clPipe"></div>
              </li>

              <li class="nav-item">
                <a id="btnSignInCompany" class="nav-link">
                  <img src="@/assets/login_aziende_jobmule_home.jpg" class="clButtonHeader" title="Login Aziende" />
                </a>
              </li>

              <li class="nav-item">
                <a id="btnSignInCandidate" class="nav-link">
                  <img src="@/assets/login_candidati_jobmule_home.jpg" class="clButtonHeader" title="Login Candidati" />
                </a>
              </li>

            </ul>

          </div>





        </div>

      </div>


    </div>


    <!-- end section header -->



    <!-- start section search -->
    <div class="searchwrap">

      <div class="container">

        <h3 style="font-family:'Orbitron', sans-serif!important;">Job Portal <span> </span></h3>



        <v-form ref="form">


          <div class="searchbar">


            <div class="srchbox">

              <div class="row">
                <div class="col-lg-9 col-md-8">
                  <label for="" style="font-family: 'Orbitron', sans-serif!important;"> {{ txtSearchKeywords }}</label>
                  <input type="text" name="search" id="jbsearch" value="" class="form-control"
                    :placeholder="txtSearchKeywordsPlaceholder" autocomplete="off"
                    style="font-family: 'Orbitron', sans-serif!important;">
                </div>
                <div class="col-lg-3 col-md-4">

                  <label for="">&nbsp;</label>

                  <img src="@/assets/search-job2.png" class="imgSearchJob" />

                </div>
              </div>


              <div class="srcsubfld additional_fields">
                <div class="row">
                  <div class="col-lg-6">

                    <div style="cursor:pointer">

                      <label style="font-family: 'Orbitron', sans-serif!important;">Select Functional Area</label>

                      <ion-item class="form-control" style="margin-left:13px;padding:0!important">

                        <ion-select id="idsubcategory" name="idsubcategory" class='idsubcategory' cancel-Text="Chiudi"
                          placeholder="Select Functional Area" done-Text="" :value="funcAreaSearch"
                          interface="action-sheet" style="font-family: 'Orbitron', sans-serif!important;">

                          <ion-select-option v-for="item in funcArea" :key="item.idsubcategory"
                            :value='item.idsubcategory' style="font-family: 'Orbitron', sans-serif!important;">
                            {{
                              item.description }}</ion-select-option>

                        </ion-select>
                      </ion-item>
                    </div>


                  </div>

                  <input id="country_id" name="country_id[]" type="hidden" value="107">

                  <div class="col-lg-3">


                    <div style="cursor:pointer">

                      <label style="font-family: 'Orbitron', sans-serif!important;">Select Region</label>

                      <ion-item class="form-control" style="margin-left:14px;padding:0!important">

                        <ion-select placeholder="Select Region" id="idregione" name="idregione" class='idregione'
                          cancel-Text="Chiudi" done-Text="" :value="regioneSearch" interface="action-sheet"
                          style="font-family: 'Orbitron', sans-serif!important;">

                          <ion-select-option v-for="item in regioni" :key="item.idregione" :value='item.idregione'
                            style="font-family: 'Orbitron', sans-serif!important;"> {{
                              item.nome }}</ion-select-option>

                        </ion-select>
                      </ion-item>
                    </div>

                  </div>
                  <div class="col-lg-3">

                    <div style="cursor:pointer">

                      <label style="font-family: 'Orbitron', sans-serif!important;">Select City</label>

                      <ion-item class="form-control" style="margin-left:12px;padding:0!important">

                        <ion-select placeholder="Select City" id="idcomune" name="idcomune" class='idcomune'
                          cancel-Text="Chiudi" done-Text="" :value="comuneSearch" interface="action-sheet"
                          style="font-family: 'Orbitron', sans-serif!important;">

                          <ion-select-option v-for="item in comuni" :key="item.idcomune" :value='item.idcomune'
                            style="font-family: 'Orbitron', sans-serif!important;"> {{
                              item.nome }}</ion-select-option>

                        </ion-select>
                      </ion-item>
                    </div>



                  </div>
                </div>
              </div>

            </div>

          </div>

        </v-form>

      </div>

    </div>
    <!-- end section search -->



    <!-- start section carousel -->

    <div class="section greybg2" id="sectionCarousel">

      <div class="container">

        <div class="titleTop">
          <h3>Hiring <span>Companies</span></h3>
        </div>


        <carousel style="margin-top:25px" :perPage="perPageCarousel">


          <slide v-for="el in companyCarousel" :key="el.companyid">

            <div style="text-align: center;">
              <img :src="el.url_logo" class="imageCarousel" :title="el.fld_name" />
            </div>

          </slide>


        </carousel>






      </div>

    </div>


    <!-- end section carousel -->








    <!-- start section search job by -->

    <div class="section greybg">

      <div class="container">

        <div class="topsearchwrap">

          <div class="tabswrap">

            <div class="row cl-title-jobsby">
              <div class="col-md-4">
                <h3 style="font-family: 'Orbitron', sans-serif!important;">Browse Jobs By</h3>
              </div>
              <div class="col-md-8">
              </div>
            </div>


          </div>


          <div class="row" style="transform: translateY(-51px);">
            <div class="col-md-12">

              <div v-if="getWindowDesktop()" class="tabs">

                <v-tabs id="vTabs" v-model="tab" right next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline" show-arrows style="max-width:100vw">

                  <v-tabs-slider color="#444"></v-tabs-slider>
                  <v-tab v-for="item in items" :key="item.description" :id="item.id" class="cl-tab"
                    style="font-family: 'Orbitron', sans-serif!important;" @click="changeTab(item.description)">
                    {{ item.description }}
                  </v-tab>

                </v-tabs>


                <v-tabs-items v-model="tab">

                  <v-tab-item key="Functional Area">


                    <v-row>

                      <v-col cols="12" md="12" xs="12">


                        <div class="srchint">

                          <ul class="row catelist" id="idTabFyncArea">


                          </ul>


                        </div>


                      </v-col>

                    </v-row>




                  </v-tab-item>

                  <v-tab-item key="Cities">

                    <v-row>

                      <v-col cols="12" md="12" xs="12">


                        <div class="srchint">

                          <ul class="row catelist" id="idTabCities">


                          </ul>


                        </div>


                      </v-col>

                    </v-row>


                  </v-tab-item>

                  <v-tab-item key="Industries">

                    <v-row>

                      <v-col cols="12" md="12" xs="12">

                        <div class="srchint">

                          <ul class="row catelist" id="idTabIndustries">


                          </ul>


                        </div>


                      </v-col>

                    </v-row>

                  </v-tab-item>

                </v-tabs-items>



              </div>


              <div v-if="getWindowMobile()" class="tabs">

                <v-tabs id="vTabs" v-model="tab" center-active centered next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline" show-arrows style="max-width:100vw">

                  <v-tabs-slider color="#444"></v-tabs-slider>
                  <v-tab v-for="item in items" :key="item.description" :id="item.id" class="cl-tab"
                    @click="changeTab(item.description)">
                    {{ item.description }}
                  </v-tab>

                </v-tabs>



                <v-tabs-items v-model="tab">

                  <v-tab-item key="Functional Area">


                    <v-row>

                      <v-col cols="12" md="12" xs="12">


                        <div class="srchint">

                          <ul class="row catelist" id="idTabFyncArea">


                          </ul>


                        </div>


                      </v-col>

                    </v-row>




                  </v-tab-item>

                  <v-tab-item key="Cities">

                    <v-row>

                      <v-col cols="12" md="12" xs="12">


                        <div class="srchint">

                          <ul class="row catelist" id="idTabCities">


                          </ul>


                        </div>


                      </v-col>

                    </v-row>


                  </v-tab-item>

                  <v-tab-item key="Industries">

                    <v-row>

                      <v-col cols="12" md="12" xs="12">

                        <div class="srchint">

                          <ul class="row catelist" id="idTabIndustries">


                          </ul>


                        </div>


                      </v-col>

                    </v-row>

                  </v-tab-item>

                </v-tabs-items>



              </div>



            </div>
          </div>


        </div>



      </div>

    </div>


    <!-- end section search job by -->

    <!-- start section login box -->

    <div class="userloginbox" id="startToday">
      <div class="container">
        <div class="titleTop">
          <h3>Are You Looking For Job! </h3>
        </div>

        <div class="viewallbtn"><a class="cl-portale-1" id="btnStartToday"> Get Started Today </a></div>
      </div>
    </div>

    <div class="userloginbox" id="startTodayNoPresent">
      <div class="container">

      </div>
    </div>

    <!-- end section login box -->




    <!-- start section how it works -->

    <div class="container">
      <div class="section howitwrap">

        <!-- title start -->
        <div class="titleTop">
          <h3>How It <span>Works</span></h3>
        </div>
        <!-- title end -->
        <ul class="howlist row">
          <!--step 1-->
          <li class="col-md-4 col-sm-4">
            <div class="iconcircle"><i class="fa fa-user" aria-hidden="true"></i>
            </div>
            <h4>Insert your mail</h4>

          </li>
          <!--step 1 end-->
          <!--step 2-->
          <li class="col-md-4 col-sm-4">
            <div class="iconcircle"><i class="fa fa-black-tie" aria-hidden="true"></i>
            </div>
            <h4>Search Desired Job</h4>

          </li>
          <!--step 2 end-->
          <!--step 3-->
          <li class="col-md-4 col-sm-4">
            <div class="iconcircle"><i class="fa fa-file-text" aria-hidden="true"></i>
            </div>
            <h4>Send Your Resume</h4>

          </li>
          <!--step 3 end-->
        </ul>
      </div>
    </div>


    <!-- end section how it works -->


    <!-- start section featured jobs -->

    <div class="section">
      <div class="container">
        <div class="titleTop">
          <h3>Featured <span>Jobs</span></h3>
        </div>

        <div id="divContFeaturedJobs">
        </div>

        <div class="viewallbtn"><a id="viewAllFeaturedJobs"
            style="font-family: 'Orbitron', sans-serif !important;color:white">View All Featured
            Jobs</a></div>
      </div>
    </div>

    <!-- end section featured jobs -->


    <!-- start section latest jobs -->


    <div class="section">
      <div class="container">
        <!-- title start -->
        <div class="titleTop">
          <h3>Latest <span>Jobs</span></h3>
        </div>
        <!-- title end -->

        <div id="divContLatestJobs">


        </div>



        <!--view button-->
        <div class="viewallbtn">

          <div class="row searchbarLatestJob" style="justify-content:center">



            <div>

              <a id="viewAllLatestJobs" style="font-family: 'Orbitron', sans-serif !important;color:white">View All
                Jobs</a>

            </div>

            <div style="margin-left:24px;">
              <select class="form-control" id="idNumLatestJobs"
                style="font-family: 'Orbitron', sans-serif!important;height:56px;padding-top: 11px;">

                <option style="font-family: 'Orbitron', sans-serif!important;" value="" selected="selected">All</option>
                <option style="font-family: 'Orbitron', sans-serif!important;" value="5">5</option>
                <option style="font-family: 'Orbitron', sans-serif!important;" value="10">10</option>

              </select>

            </div>






          </div>




        </div>
        <!--view button end-->
      </div>
    </div>

    <!-- end section latest jobs -->


    <!-- start section subscribe newsletter -->
    <div style="height:50px">&nbsp;</div>

    <div class="container" id="newsLetterJobmule">
      <div class="section howitwrap">

        <div class="titleTop">
          <h3>Newsletter</h3>
        </div>

        <!-- <div class="viewallbtn"><a class="cl-portale-1" id="btnSubscribeNewsletter"> Newsletter </a></div> -->

        <div style="text-align:center">
          <a id="btnSubscribeNewsletter">
            <img src="@/assets/newsletter_jobmule.png" style="width: 84px;" />
          </a>
        </div>

      </div>
    </div>

    <div class="container" id="newsLetterCareers">
      <div class="section howitwrap">

        <div class="titleTop">
          <h3>Newsletter</h3>
        </div>

        <!-- <div class="viewallbtn"><a class="cl-portale-1" id="btnSubscribeNewsletter"> Newsletter </a></div> -->

        <div style="text-align:center">
          <a id="btnSubscribeNewsletter">
            <img src="@/assets/newsletter_careers.png" style="width: 84px;" />
          </a>
        </div>

      </div>
    </div>

    <!-- <img src="@/assets/search-job2.png" class="imgSearchJob" /> -->



    <!-- end section subscribe newsletter -->




    <!-- start section footer -->

    <div class="footerWrap">
      <div class="container">
        <div class="row">

          <!--Quick Links-->
          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Quick Links</h5>
            <!--Quick Links menu Start-->
            <ul class="quicklinks">

              <li class="">

                <a style="font-family: 'Orbitron', sans-serif!important;" class="lnkTermOfUse">Terms Of Use</a>
                <a style="font-family: 'Orbitron', sans-serif!important;" class="lnkPrivacyHome">Privacy</a>

              </li>
            </ul>
          </div>
          <!--Quick Links menu end-->

          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Jobs By Functional Area</h5>
            <!--Quick Links menu Start-->

            <ul class="quicklinks" id="jobsByFuncAreaQuickLinks">




            </ul>

          </div>

          <!--Jobs By Industry-->
          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Jobs By Industry</h5>

            <!--Industry menu Start-->
            <ul class="quicklinks" id="jobsByIndustryQuickLinks">

            </ul>

            <!--Industry menu End-->
            <div class="clear"></div>
          </div>

          <!--About Us-->
          <div class="col-md-3 col-sm-12">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Contact Us</h5>

            <div class="txtInfoCompany">


            </div>



            <div class="social"></div>
            <!-- Social Icons end -->

          </div>
          <!--About us End-->


        </div>
      </div>
    </div>

    <!-- end section footer -->

    <!-- start section copyright -->


    <div class="copyright" style="padding:0!important">
      <div class="container">
        <div class="row">
          <div class="col-md-8">


            <div class="bttxt">

            </div>



          </div>
          <div class="col-md-4">

          </div>
        </div>

      </div>
    </div>


    <!-- end section copyright -->


    <div class="contpopuptermuse" style="display:none">

      <template>
        <modal name="popupTermUse" :clickToClose="false" :width="getWindowMobileTermOfUse()" :height="460">


          <v-row>

            <v-col cols="12" md="12">


              <div class="clTermOfUse">



              </div>



            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">


                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_careers.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmTermOfUse" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>



    <div class="contpopupnewsletter" style="display:none">

      <template>
        <modal name="popupNewsletter" :clickToClose="false" :width="262" :height="215">


          <v-row>

            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px;font-size: 12px;">

              Subscribe Newsletter

            </v-col>


          </v-row>

          <v-row>

            <v-col cols="12" md="12">

              <v-text-field type="text" v-model="valueMailNewsletter" label="E-Mail" id="fldValueMailNewsletter">
              </v-text-field>

            </v-col>

          </v-row>


          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">

                <v-img v-if="isCareers" alt="" class="btn-cancel-newsletter" contain
                  src="@/assets/btn_cancel3_careers.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Annulla" @click="btnCancelNewsletter" />


                <v-img v-if="isCareers" alt="" class="btn-confirm-newsletter" contain
                  src="@/assets/btn_confirm3_careers.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmNewsletter" />

                <v-img v-if="isJobmule" alt="" class="btn-cancel-newsletter" contain
                  src="@/assets/btn_cancel3_jobmule.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Annulla" @click="btnCancelNewsletter" />


                <v-img v-if="isJobmule" alt="" class="btn-confirm-newsletter" contain
                  src="@/assets/btn_confirm3_jobmule.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmNewsletter" />

              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>

    <div class="contpopupotpnewsletter" style="display:none">

      <template>
        <modal name="popupOtpNewsletter" :clickToClose="false" :width="262" :height="221">


          <v-row>

            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px;font-size: 12px;">

              Codice OTP inviatoLe via mail

            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" md="12">

              <v-text-field type="password" v-model="valueOtpNewsletter" label="" id="fldValueOtpNewsletter">
              </v-text-field>

            </v-col>

          </v-row>

          <v-row v-if="showResend">

            <v-col cols="12" md="12" style="display: flex;
                        justify-content: center;
                        padding-top: 0px!important;
                        margin-top: 0px!important;
                        font-size: 12px;">

              <div>OTP non ricevuto ? </div>
              <div style="font-weight: bold;
                        margin-left: 10px;
                        cursor: pointer;" class="clReinviaOtpNewsletter">Reinvia</div>

            </v-col>

          </v-row>


          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">

                <v-img v-if="isCareers" alt="" class="btn-cancel-otp-newsletter" contain
                  src="@/assets/btn_cancel3_careers.png" max-width="30" max-height="30"
                  style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition" title="Annulla"
                  @click="btnCancelOtpNewsletter" />

                <v-img v-if="isCareers" alt="" class="btn-confirm-otp-newsletter" contain
                  src="@/assets/btn_confirm3_careers.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmOtpNewsletter" />

                <v-img v-if="isJobmule" alt="" class="btn-cancel-otp-newsletter" contain
                  src="@/assets/btn_cancel3_jobmule.png" max-width="30" max-height="30"
                  style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition" title="Annulla"
                  @click="btnCancelOtpNewsletter" />

                <v-img v-if="isJobmule" alt="" class="btn-confirm-otp-newsletter" contain
                  src="@/assets/btn_confirm3_jobmule.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmOtpNewsletter" />

              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>


    <div class="contpopupotpchoosenewsletter" style="display:none">

      <template>
        <modal name="popupOtpChooseNewsletter" :clickToClose="false" :width="262" :height="215">


          <v-row>

            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px;font-size:12px">

              Choose Operation

            </v-col>

          </v-row>

          <v-row
            style="margin-top:0px!important;padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;">

            <v-col cols="12" md="12">

              <v-radio-group v-model="chooseOperationNewsletter">

                <v-radio label="Subscribe" value="S"></v-radio>
                <v-radio label="Unsubscribe" value="U"></v-radio>


              </v-radio-group>

            </v-col>

          </v-row>

          <v-row style="margin-top:0px!important;padding-top:0px!important">

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">

                <v-img v-if="isCareers" alt="" class="btn-cancel-choose-newsletter" contain
                  src="@/assets/btn_cancel3_careers.png" max-width="30" max-height="30"
                  style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition" title="Annulla"
                  @click="btnCancelChooseNewsletter" />

                <v-img v-if="isCareers" alt="" class="btn-confirm-choose-newsletter" contain
                  src="@/assets/btn_confirm3_careers.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmChooseNewsletter" />

                <v-img v-if="isJobmule" alt="" class="btn-cancel-choose-newsletter" contain
                  src="@/assets/btn_cancel3_jobmule.png" max-width="30" max-height="30"
                  style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition" title="Annulla"
                  @click="btnCancelChooseNewsletter" />

                <v-img v-if="isJobmule" alt="" class="btn-confirm-choose-newsletter" contain
                  src="@/assets/btn_confirm3_jobmule.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmChooseNewsletter" />

              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>


    <div class="contpopupunsubnewsletter" style="display:none">

      <template>
        <modal name="popupUnsubNewsletter" :clickToClose="false" :width="262" :height="215">


          <v-row>

            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px;font-size:12px">

              Unsubscribe Newsletter

            </v-col>


          </v-row>

          <v-row>

            <v-col cols="12" md="12">

              <v-text-field type="text" v-model="valueMailNewsletter" label="E-Mail" id="fldValueMailNewsletter">
              </v-text-field>

            </v-col>

          </v-row>


          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">

                <v-img v-if="isCareers" alt="" class="btn-cancel-unsub-newsletter" contain
                  src="@/assets/btn_cancel3_careers.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Annulla" @click="btnCancelUnsubNewsletter" />


                <v-img v-if="isCareers" alt="" class="btn-confirm-unsub-newsletter" contain
                  src="@/assets/btn_confirm3_careers.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmUnsubNewsletter" />

                <v-img v-if="isJobmule" alt="" class="btn-cancel-unsub-newsletter" contain
                  src="@/assets/btn_cancel3_jobmule.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Annulla" @click="btnCancelUnsubNewsletter" />


                <v-img v-if="isJobmule" alt="" class="btn-confirm-unsub-newsletter" contain
                  src="@/assets/btn_confirm3_jobmule.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmUnsubNewsletter" />

              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>


    <div class="contpopupotpunsubnewsletter" style="display:none">

      <template>
        <modal name="popupOtpUnsubNewsletter" :clickToClose="false" :width="262" :height="221">


          <v-row>

            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px;font-size:12px">

              Codice OTP inviatoLe via mail

            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" md="12">

              <v-text-field type="password" v-model="valueOtpNewsletter" label="" id="fldValueOtpUnsubNewsletter">
              </v-text-field>

            </v-col>

          </v-row>

          <v-row v-if="showResend">

            <v-col cols="12" md="12" style="display: flex;
                  justify-content: center;
                  padding-top: 0px!important;
                  margin-top: 0px!important;
                  font-size:12px">

              <div>OTP non ricevuto ? </div>
              <div style="font-weight: bold;
                  margin-left: 10px;
                  cursor: pointer;" class="clReinviaOtpUnsubNewsletter">Reinvia</div>

            </v-col>

          </v-row>


          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">

                <v-img v-if="isCareers" alt="" class="btn-cancel-otp-unsub-newsletter" contain
                  src="@/assets/btn_cancel3_careers.png" max-width="30" max-height="30"
                  style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition" title="Annulla"
                  @click="btnCancelOtpUnsubNewsletter" />

                <v-img v-if="isCareers" alt="" class="btn-confirm-otp-unsub-newsletter" contain
                  src="@/assets/btn_confirm3_careers.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmOtpUnsubNewsletter" />

                <v-img v-if="isJobmule" alt="" class="btn-cancel-otp-unsub-newsletter" contain
                  src="@/assets/btn_cancel3_jobmule.png" max-width="30" max-height="30"
                  style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition" title="Annulla"
                  @click="btnCancelOtpUnsubNewsletter" />

                <v-img v-if="isJobmule" alt="" class="btn-confirm-otp-unsub-newsletter" contain
                  src="@/assets/btn_confirm3_jobmule.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Conferma" @click="btnConfirmOtpUnsubNewsletter" />

              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>



    <div class="contpopupprivacyhome" style="display:none">

      <template>
        <modal name="popupPrivacyHome" :clickToClose="false" :width="getWindowMobilePrivacyHome()" :height="460">


          <v-row>

            <v-col cols="12" md="12">


              <div class="clPrivacyHome">



              </div>



            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">


                <v-img v-if="isJobmule" alt="" class="btn-confirm-privacy-home" contain
                  src="@/assets/btn_cancel3_jobmule.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Chiudi" @click="btnConfirmPrivacyHome" />

                <v-img v-if="isCareers" alt="" class="btn-confirm-privacy-home" contain
                  src="@/assets/btn_cancel3_careers.png" max-width="30" max-height="30" style="cursor:pointer"
                  transition="scale-transition" title="Chiudi" @click="btnConfirmPrivacyHome" />

              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>











  </div>
</template>


<style scoped lang="css">
@import "./../assets/annunci/bootstrap-datepicker3.min.css";
@import "./../assets/annunci/bootstrap.min.css";
@import "./../assets/annunci/main.css";
/* @import "./../assets/annunci/owl.carousel.css"; */
@import "./../assets/annunci/select2-bootstrap.min.css";
@import "./../assets/annunci/select2.min.css";
/* @import "./../assets/annunci/settings.css"; */
/* @import "./../assets/annunci/slick-theme.css"; */
/* @import "./../assets/annunci/slick-theme.min.css"; */
@import "./../assets/annunci/slick.css";
@import "./../assets/annunci/style-font1.css";
@import "./../assets/annunci/style-font2.css";
@import "./../assets/annunci/font-awesome.css";
</style>

<script>
//@import "@/assets/loginstart/style.css"
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

//import RiseLoader from "vue-spinner/src/RiseLoader.vue";

import router from ".././router";
import apisearchads from "../utils/searchads/apisearchads";
import apiotp from "../utils/otp/apiotp";
import apinewsletter from "../utils/newsletter/apinewsletter";

import { Carousel, Slide } from 'vue-carousel';


import $ from 'jquery';

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {

  components: {
    Carousel,
    Slide
  },

  updated() { },


  mounted: function () {


    console.log("VAL SITE TEST: ", window.siteForTest);


    // window.urlSiteCareers = this.getUrlDomain();

    // // x test
    // window.urlSiteCareers = "https://careers.lc-service.it/";

    this.setUrlDomainGlobal();

    this.perPageCarousel = this.getWindowMobilePerPageCarousel();


    $("#divHeadBtnsJobmule").hide();
    $("#sectionCarousel").hide();

    $("#newsLetterJobmule").hide();
    $("#newsLetterCareers").show();

    this.txtSearchKeywords = "Keywords / Job Title / Job Description / Job Location";
    this.txtSearchKeywordsPlaceholder = "Enter Skills or job title or job description or job location";

    $("#startTodayNoPresent").hide();

    this.isCareers = true;
    this.isJobmule = false;

    if (window.urlSiteCareers == "https://jobmule.eu/") {

      $("#sectionCarousel").show();

      $("#startToday").hide();
      $("#startTodayNoPresent").show();

      $("#divHeadBtns").hide();
      $("#divHeadBtnsJobmule").show();

      this.txtSearchKeywords = "Keywords / Job Title / Job Description / Job Location / Company";

      this.txtSearchKeywordsPlaceholder = "Enter Skills or job title or job description or job location or company";

      $("#newsLetterJobmule").show();
      $("#newsLetterCareers").hide();

      this.isCareers = false;
      this.isJobmule = true;

    }


    console.log("URL SITE", window.urlSiteCareers);


    window.$cookies.set("filter_ads_featured", "", "9y");
    window.$cookies.set("page_go_back", "", "9y");

    window.$cookies.set("filter_ads_job_by_title", "", "9y");
    window.$cookies.set("filter_ads_job_by_country", "", "9y");
    window.$cookies.set("filter_ads_job_by_state", "", "9y");
    window.$cookies.set("filter_ads_job_by_city", "", "9y");
    window.$cookies.set("filter_ads_job_by_exp", "", "9y");
    window.$cookies.set("filter_ads_job_by_type", "", "9y");


    var loggedIn = this.$cookies.get('token');

    if (loggedIn) {
      router.push({ path: "/dash" });
    }
    console.log("Ciao: " + this.userLogged);

    var pointerVue = this;

    $(".searchwrap").css("background", "url('')");


    $(".userloginbox").css("background", "url('')");




    setTimeout(() => {

      pointerVue.getValuesSearch();


    }, 1000);


    console.log("RELOAD ONCE: ", window.reloadOnce);




    $(document).ready(function () {


      if (window.urlSiteCareers == "https://jobmule.eu/") {

        document.title = "jobmule";

        $('link[rel="icon"]').attr('href', '/careers/favicon_jobmule.ico');

      }
      else {

        $('link[rel="icon"]').remove();


      }


      $('body').on('click', '.clReinviaOtpNewsletter', function (event) {
        event.preventDefault();

        pointerVue.showResend = false;

        pointerVue.$modal.hide('popupOtpNewsletter');


        setTimeout(() => {

          pointerVue.getOtpNewsletter();

        }, 300);


      });



      $('body').on('click', '.clReinviaOtpUnsubNewsletter', function (event) {
        event.preventDefault();

        pointerVue.showResend = false;

        pointerVue.$modal.hide('popupOtpUnsubNewsletter');


        setTimeout(() => {

          pointerVue.getOtpUnsubNewsletter();

        }, 300);


      });













      $('body').on('click', '.lnkTermOfUse', function (event) {
        event.preventDefault();


        $(".contpopuptermuse").show();

        pointerVue.$modal.show('popupTermUse');


        setTimeout(() => {

          $(".clTermOfUse").html(pointerVue.txtTermOfUse);

        }, 200);


      });



      $('body').on('click', '.lnkPrivacyHome', function (event) {
        event.preventDefault();

        router.push({ path: '/privacyHome' }).catch(() => { });

      });












      $('body').on('click', '.clButtonRefresh', function (event) {
        event.preventDefault();

        router.push({ path: '/reloadPage/searchAds' }).catch(() => { });


      });


      $('body').on('click', '.imageCarousel', function (event) {
        event.preventDefault();

        console.log("CAROUSEL");

        var txt_search = $(this).prop("title").toUpperCase();

        console.log("TITLE: ", txt_search);



        window.$cookies.set("filter_ads_txt_search", txt_search, "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");


        window.$cookies.set("filter_ads_job_by_industry", "", "9y");

        window.$cookies.set("filter_ads_job_by_func_area", "", "9y");

        router.push({ path: '/adsGrid' }).catch(() => { });

      });


      $('body').on('click', '.imgSearchJob', function (event) {
        event.preventDefault();

        console.log("IMG_SEARCH_JOB");

        var txt_search = $("#jbsearch").val();

        var id_subcat = $("#idsubcategory").val();

        var id_regione = $("#idregione").val();

        var id_comune = $("#idcomune").val();


        console.log("TXT_SEARCH: ", txt_search);

        console.log("ID_SUBCAT: ", id_subcat);

        console.log("ID_REGIONE: ", id_regione);

        console.log("ID_COMUNE: ", id_comune);



        window.$cookies.set("filter_ads_txt_search", txt_search, "9y");

        window.$cookies.set("filter_ads_id_regione", id_regione, "9y");

        window.$cookies.set("filter_ads_id_comune", id_comune, "9y");

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", id_subcat, "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");


        window.$cookies.set("filter_ads_job_by_industry", "", "9y");

        window.$cookies.set("filter_ads_job_by_func_area", "", "9y");

        router.push({ path: '/adsGrid' }).catch(() => { });

      });


      $('body').on('click', '.clIndustries', function (event) {
        event.preventDefault();

        var id_cat = $(this).attr("data-id");

        console.log("ID_CATEGORY: ", id_cat);

        window.$cookies.set("filter_ads_id_cat", id_cat, "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        window.$cookies.set("filter_ads_job_by_industry", id_cat, "9y");

        window.$cookies.set("filter_ads_job_by_func_area", "", "9y");

        router.push({ path: '/initAdsGrid' }).catch(() => { });

      });



      $('body').on('click', '.clCities', function (event) {
        event.preventDefault();

        var id_city = $(this).attr("data-id");

        console.log("ID_CITY: ", id_city);

        window.$cookies.set("filter_ads_id_city", id_city, "9y");

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");


        router.push({ path: '/initAdsGrid' }).catch(() => { });

      });





      $('body').on('click', '.clFuncArea', function (event) {
        event.preventDefault();

        var id_subcat = $(this).attr("data-id");

        console.log("ID_SUB_CAT: ", id_subcat);

        window.$cookies.set("filter_ads_id_sub_cat", id_subcat, "9y");

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        window.$cookies.set("filter_ads_job_by_func_area", id_subcat, "9y");

        window.$cookies.set("filter_ads_job_by_industry", "", "9y");

        router.push({ path: '/initAdsGrid' }).catch(() => { });

      });


      $('body').on('click', '#btnHome', function (event) {
        event.preventDefault();

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");


        router.push({ path: '/searchAds' }).catch(() => { });

      });


      $('body').on('click', '#btnAllJobs', function (event) {
        event.preventDefault();

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");


        router.push({ path: '/adsGrid' }).catch(() => { });

      });


      $('body').on('click', '#viewAllLatestJobs', function (event) {
        event.preventDefault();

        console.log("N LATEST JOBS: ", $("#idNumLatestJobs").val());

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", $("#idNumLatestJobs").val(), "9y");

        window.$cookies.set("filter_ads_job_by_func_area", "", "9y");

        window.$cookies.set("filter_ads_job_by_industry", "", "9y");

        router.push({ path: '/adsGrid' }).catch(() => { });

      });


      $('body').on('click', '#viewAllFeaturedJobs', function (event) {
        event.preventDefault();

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "S", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        window.$cookies.set("filter_ads_job_by_func_area", "", "9y");

        window.$cookies.set("filter_ads_job_by_industry", "", "9y");

        router.push({ path: '/adsGrid' }).catch(() => { });

      });


      $('body').on('click', '#btnSubscribeNewsletter', function (event) {
        event.preventDefault();

        console.log("ok");

        pointerVue.valueMailNewsletter = null;

        pointerVue.isJobmule = true;
        pointerVue.isCareers = false;

        if (window.urlSiteCareers != "https://jobmule.eu/") {

          pointerVue.isJobmule = false;
          pointerVue.isCareers = true;

        }


        pointerVue.chooseOperationNewsletter = "S";



        setTimeout(() => {

          $(".contpopupotpchoosenewsletter").show();

          pointerVue.$modal.show('popupOtpChooseNewsletter');



        }, 200);


      });





      $('body').on('click', '#btnStartToday', function (event) {
        event.preventDefault();

        router.push({ path: '/getStartToday' }).catch(() => { });

      });



      $('body').on('ionChange', '.idregione', function (event) {
        event.preventDefault();

        pointerVue.setComuni(this.value);


      });

      $('body').on('click', '#btnSignInCompany', function (event) {
        event.preventDefault();

        window.$cookies.set("page_go_back", "searchAds", "9y");

        if (window.urlSiteCareers != "https://jobmule.eu/") {


          router.push({ path: '/loginAzienda' }).catch(() => { });

        }
        else {

          router.push({ path: '/loginAziendaJobmule' }).catch(() => { });

        }



      });


      $('body').on('click', '#btnSignInCandidate', function (event) {
        event.preventDefault();

        window.$cookies.set("page_go_back", "searchAds", "9y");


        if (window.urlSiteCareers != "https://jobmule.eu/") {

          router.push({ path: '/loginCandidati' }).catch(() => { });

        }
        else {

          router.push({ path: '/loginCandidatiJobmule' }).catch(() => { });

        }



      });










      $('body').on('click', '.viewAds', function (event) {
        event.preventDefault();

        var v_id = $(this).attr("data-id");

        console.log("VAL ID: ", v_id);

        window.$cookies.set("id_ads_detail", v_id, "9y");

        router.push({
          path: "/adsDetail"
        });

      });



    });



  },
  name: "Login",

  // data:

  data() {
    return {


      chooseOperationNewsletter: "S",


      isCareers: false,
      isJobmule: false,


      showResend: false,

      valueOtpNewsletter: "",

      valueMailNewsletter: "",


      perPageCarousel: 4,

      txtSearchKeywords: "",
      txtSearchKeywordsPlaceholder: "",



      txtTermOfUse: "",

      txtPrivacyHome: "",

      txtPrivacy: "",


      textPoweredBy: "",


      pathLogo: "",

      dialogRegister: true,

      notifications: false,
      sound: true,
      widgets: false,

      showSpinner: false,
      input: {
        username: "",
        password: "",
      },

      nameSearch: "",
      funcAreaSearch: "0",
      regioneSearch: "0",
      comuneSearch: "0",

      funcArea: [],
      regioni: [],
      comuni: [],
      comuniTemp: [],


      companyCarousel: [],

      // items: [
      //   'Functional Area', 'Cities', 'Industries'
      // ],

      items: [
        { id: "tbFunctionalArea", description: "Functional Area" },
        { id: "tbCities", description: "Cities" },
        { id: "tbIndustries", description: "Industries" }
      ],

      adsFuncArea: [],
      adsCities: [],
      adsIndustries: [],

      tab: null,


    };
  },
  methods: {

    btnConfirmPrivacyHome: function () {

      this.$modal.hide('popupPrivacyHome');

    },


    btnCancelOtpUnsubNewsletter: function () {

      this.$modal.hide('popupOtpUnsubNewsletter');

    },

    btnConfirmOtpUnsubNewsletter: function () {


      if (this.valueOtpNewsletter != "") {

        console.log("OTP INP: ", this.valueOtpNewsletter);
        console.log("ENC OTP INP: ", btoa(this.valueOtpNewsletter));

        if (window.myVal == btoa(this.valueOtpNewsletter)) {

          this.$modal.hide('popupOtpUnsubNewsletter');

          this.unregisterNewsletter();


        }
        else {

          this.$swal({
            icon: "error",
            text: "Valore OTP non corretto",
            showConfirmButton: false,
            timer: 2000
          });


        }



      }
      else {

        this.$swal({
          icon: "error",
          text: "Specificare un  valore dell'OTP",
          showConfirmButton: false,
          timer: 2000
        });

      }




    },






    btnCancelUnsubNewsletter: function () {

      this.$modal.hide('popupUnsubNewsletter');

    },


    btnConfirmUnsubNewsletter: function () {

      var errore = "";

      if (this.valueMailNewsletter == "") {

        errore = "Specificare una mail";

      }

      if (errore == "") {

        if (!this.validateEMail(this.valueMailNewsletter)) {
          errore = "E-Mail non valida";

        }

      }


      if (errore == "") {

        console.log("ok");

        this.getOtpUnsubNewsletter();


      }
      else {

        this.$swal({
          icon: "error",
          text: errore,
          showConfirmButton: false,
          timer: 2000
        });


      }




    },






    btnCancelChooseNewsletter: function () {

      this.$modal.hide('popupOtpChooseNewsletter');


    },

    btnConfirmChooseNewsletter: function () {

      this.$modal.hide('popupOtpChooseNewsletter');


      this.valueMailNewsletter = "";

      if (this.chooseOperationNewsletter == "S") { // subscribe


        setTimeout(() => {


          $(".contpopupnewsletter").show();

          this.$modal.show('popupNewsletter');

        }, 200);



      }

      if (this.chooseOperationNewsletter == "U") {

        setTimeout(() => {


          $(".contpopupunsubnewsletter").show();

          this.$modal.show('popupUnsubNewsletter');

        }, 200);

      }


    },


    unregisterNewsletter: async function () {

      var that = this;

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apinewsletter.unregisterNewsletter(
        window.urlSiteCareers,
        that.valueMailNewsletter
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from unregisterNewsletter", res);

        if (res.data.Result == "OK") {

          that.$swal({
            icon: "success",
            text: "Eliminazione registrazione newsletter effettuata correttamente",
            showConfirmButton: false,
            timer: 3000
          });

        }
        else {

          that.$swal({
            icon: "error",
            text: res.data.ErrDetails,
            showConfirmButton: false,
            timer: 3000
          });


        }




      }).catch(err => {
        that.showSpinner = false;
        // that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );


    },





    registerNewsletter: async function () {

      var that = this;

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apinewsletter.registerNewsletter(
        window.urlSiteCareers,
        that.valueMailNewsletter
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from registerNewsletter", res);

        if (res.data.Result == "OK") {

          that.$swal({
            icon: "success",
            text: "E-Mail registrata correttamente, una e-mail di conferma Le è stata inviata",
            showConfirmButton: false,
            timer: 3000
          });

        }
        else {

          that.$swal({
            icon: "error",
            text: res.data.ErrDetails,
            showConfirmButton: false,
            timer: 3000
          });


        }




      }).catch(err => {
        that.showSpinner = false;
        // that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );


    },


    getOtpUnsubNewsletter: async function () {

      this.$modal.hide('popupUnsubNewsletter');

      var that = this;

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var v_portale = "jobmule";
      var v_company_id = 0;

      that.isJobmule = true;
      that.isCareers = false;

      if (window.urlSiteCareers != "https://jobmule.eu/") {

        v_portale = "careers";

        v_company_id = 7;
        if (window.urlSiteCareers == "https://careers.ariall.eu/") {
          v_company_id = 8;
        }

        that.isJobmule = false;
        that.isCareers = true;

      }

      var response = await apiotp.generateOTPAndSendMailLogin(
        that.valueMailNewsletter,
        v_portale,
        'unsubscribe_newsletter',
        v_company_id
      ).then((res) => {

        that.$root.$children[0].showProgress = false;


        console.log("res from generateOTPAndSendMailLogin", res);

        window.myVal = res.data.Result;


        setTimeout(() => {

          that.showResend = true;

          that.valueOtpNewsletter = "";

          $(".contpopupotpunsubnewsletter").show();

          this.$modal.show('popupOtpUnsubNewsletter');

          setTimeout(() => {

            $("#fldValueOtpUnsubNewsletter").focus();

          }, 300);


        }, 200);





      }).catch(err => {
        that.showSpinner = false;
        // that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );



    },





    getOtpNewsletter: async function () {

      this.$modal.hide('popupNewsletter');

      var that = this;

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var v_portale = "jobmule";
      var v_company_id = 0;

      that.isJobmule = true;
      that.isCareers = false;

      if (window.urlSiteCareers != "https://jobmule.eu/") {

        v_portale = "careers";

        v_company_id = 7;
        if (window.urlSiteCareers == "https://careers.ariall.eu/") {
          v_company_id = 8;
        }

        that.isJobmule = false;
        that.isCareers = true;

      }

      var response = await apiotp.generateOTPAndSendMailLogin(
        that.valueMailNewsletter,
        v_portale,
        'subscribe_newsletter',
        v_company_id
      ).then((res) => {

        that.$root.$children[0].showProgress = false;


        console.log("res from generateOTPAndSendMailLogin", res);

        window.myVal = res.data.Result;


        setTimeout(() => {

          that.showResend = true;

          that.valueOtpNewsletter = "";

          $(".contpopupotpnewsletter").show();

          this.$modal.show('popupOtpNewsletter');

          setTimeout(() => {

            $("#fldValueOtpNewsletter").focus();

          }, 300);


        }, 200);





      }).catch(err => {
        that.showSpinner = false;
        // that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );



    },


    btnCancelOtpNewsletter: function () {

      this.$modal.hide('popupOtpNewsletter');



    },

    btnConfirmOtpNewsletter: function () {


      if (this.valueOtpNewsletter != "") {

        console.log("OTP INP: ", this.valueOtpNewsletter);
        console.log("ENC OTP INP: ", btoa(this.valueOtpNewsletter));

        if (window.myVal == btoa(this.valueOtpNewsletter)) {

          this.$modal.hide('popupOtpNewsletter');

          this.registerNewsletter();


        }
        else {

          this.$swal({
            icon: "error",
            text: "Valore OTP non corretto",
            showConfirmButton: false,
            timer: 2000
          });


        }



      }
      else {

        this.$swal({
          icon: "error",
          text: "Specificare un  valore dell'OTP",
          showConfirmButton: false,
          timer: 2000
        });

      }

    },



    validateEMail: function (v_email) {

      var result;

      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      console.log("MAIL: ", v_email);

      if (v_email != undefined) {

        if (v_email != "") {

          if (v_email.match(validRegex)) {
            result = true;
          }
          else {
            result = false;

          }


        }
        else {

          result = true;

        }


      }
      else {
        result = true;

      }


      return result;

    },


    btnCancelNewsletter: function () {

      this.$modal.hide('popupNewsletter');


    },

    btnConfirmNewsletter: function () {

      var errore = "";

      if (this.valueMailNewsletter == "") {

        errore = "Specificare una mail";

      }

      if (errore == "") {

        if (!this.validateEMail(this.valueMailNewsletter)) {
          errore = "E-Mail non valida";

        }

      }


      if (errore == "") {

        console.log("ok");

        this.getOtpNewsletter();


      }
      else {

        this.$swal({
          icon: "error",
          text: errore,
          showConfirmButton: false,
          timer: 2000
        });


      }



    },


    setUrlDomainGlobal: function () {

      let result = "";

      if (window.siteForTest == "") {

        if (location.hostname == "localhost") {
          result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

          result = location.protocol + "//" + location.hostname + "/";

        }

      }
      else {

        result = window.siteForTest;

      }



      window.urlSiteCareers = result;

    },


    btnConfirmTermOfUse: function () {

      this.$modal.hide('popupTermUse');


    },


    getWindowMobileTermOfUse: function () {

      if (window.innerWidth <= 768) {

        return 350;

      }
      else {

        return 616;

      }

    },


    getWindowMobilePrivacyHome: function () {

      if (window.innerWidth <= 768) {

        return 350;

      }
      else {

        return 616;

      }

    },





    getWindowMobilePerPageCarousel: function () {

      if (window.innerWidth <= 768) {

        return 2;

      }
      else {

        return 4;

      }

    },


    getWindowMobile: function () {

      if (window.innerWidth <= 768) {

        return true;

      }
      else {

        return false;

      }

    },


    getWindowDesktop: function () {

      if (window.innerWidth > 768) {

        return true;

      }
      else {

        return false;

      }

    },


    manageTabs: function () {

      if (window.innerWidth <= 768) {

        $("#vTabs").removeClass("v-tabs--right");
        $("#vTabs").addClass("v-tabs--centered");

      }
      else {

        $("#vTabs").addClass("v-tabs--right");
        $("#vTabs").removeClass("v-tabs--centered");

      }
    },

    getClass() {

      console.log("WIND WID: ", window.innerWidth);

      if (window.innerWidth <= 768) {

        return 'center-active centered'
      }


      else {
        return 'right'
      }

    },

    changeTab: function (v_tab) {

      console.log("TAB:", v_tab);

      if (v_tab == "Functional Area") {


        setTimeout(() => {

          $("#tbFunctionalArea").removeClass("cl-tab");
          $("#tbFunctionalArea").addClass("cl-tab-select");

          $("#tbIndustries").removeClass("cl-tab-select");
          $("#tbIndustries").addClass("cl-tab");

          $("#tbCities").removeClass("cl-tab-select");
          $("#tbCities").addClass("cl-tab");


        }, 200);


      }



      if (v_tab == "Cities") {


        setTimeout(() => {

          $("#idTabCities").empty();

          // browse job by cities
          var v_tot_cities = this.adsCities;

          for (var x = 0; x < v_tot_cities.length; x++) {

            // var txt = `<li class="col-md-4 col-sm-6"><a style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" title=""` + v_tot_cities[x].nome + `"">` + v_tot_cities[x].nome + ` <span>(` + v_tot_cities[x].v_tot + `)</span></a></li>`;

            var txt = `<li class="col-md-4 col-sm-6"><a class='clCities' style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_cities[x].idprovincia + `' title=""` + v_tot_cities[x].nome + `"">` + v_tot_cities[x].nome + ` <span>(` + v_tot_cities[x].v_tot + `)</span></a></li>`;




            $("#idTabCities").append(txt);


            $("#tbFunctionalArea").removeClass("cl-tab-select");
            $("#tbFunctionalArea").addClass("cl-tab");

            $("#tbIndustries").removeClass("cl-tab-select");
            $("#tbIndustries").addClass("cl-tab");

            $("#tbCities").removeClass("cl-tab");
            $("#tbCities").addClass("cl-tab-select");


          }


        }, 200);



      }

      if (v_tab == "Industries") {


        setTimeout(() => {

          $("#idTabIndustries").empty();

          // browse job by cities
          var v_tot_industries = this.adsIndustries;

          console.log("INDUSTRIES: ", v_tot_industries);


          for (var x = 0; x < v_tot_industries.length; x++) {

            var txt = `<li class="col-md-4 col-sm-6"><a class='clIndustries' style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_industries[x].idcategory + `' title=""` + v_tot_industries[x].v_nome + `"">` + v_tot_industries[x].v_nome + ` <span>(` + v_tot_industries[x].v_tot + `)</span></a></li>`;




            $("#idTabIndustries").append(txt);



            $("#tbFunctionalArea").removeClass("cl-tab-select");
            $("#tbFunctionalArea").addClass("cl-tab");

            $("#tbCities").removeClass("cl-tab-select");
            $("#tbCities").addClass("cl-tab");


            $("#tbIndustries").removeClass("cl-tab");
            $("#tbIndustries").addClass("cl-tab-select");


          }


        }, 200);



      }


    },



    setComuni: function (id_regione) {

      console.log("PROV SEL 2: ", id_regione);

      console.log("COMUNI TMP: ", this.comuniTemp);

      this.comuni = [];

      var Comuni = [];



      if (window.urlSiteCareers != "https://jobmule.eu/") {

        Comuni.push({
          idcomune: "0",
          idregione: "0",
          nome: "Select City"
        });

        for (var i = 0; i < this.comuniTemp.length; i++) {

          if (parseInt(this.comuniTemp[i].idregione) == parseInt(id_regione)) {

            Comuni.push({
              idcomune: this.comuniTemp[i].idcomune,
              idregione: this.comuniTemp[i].idregione,
              nome: this.comuniTemp[i].nome
            });

          }

        }

        console.log("COMUNI SEL: ", Comuni);

        this.comuni = Comuni;

      }
      else {


        var desc_regione = "";
        for (var j = 0; j < this.regioni.length; j++) {
          if (parseInt(this.regioni[j].idregione) == parseInt(id_regione)) {

            desc_regione = this.regioni[j].nome.toUpperCase();

          }
        }





        Comuni.push({
          idcomune: "0",
          idregione: "0",
          nome: "Select City",
          nome_reg: ""
        });

        for (var i2 = 0; i2 < this.comuniTemp.length; i2++) {



          if (this.comuniTemp[i2].nome_reg.toUpperCase() == desc_regione) {

            Comuni.push({
              idcomune: this.comuniTemp[i2].idcomune,
              idregione: this.comuniTemp[i2].idregione,
              nome: this.comuniTemp[i2].nome,
              nome_reg: this.comuniTemp[i2].nome_reg
            });



          }



        }

        console.log("COMUNI SEL: ", Comuni);

        this.comuni = Comuni;

      }







    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    ,
    getCookie() {
      this.$cookies.set("cookie-consent-performance", "yes");

      //  this.$swal('You are logged in!!!');

      //   console.log(Vue.cookie.get('token'));

      // it gets the cookie called `username`
      /* const username = this.$cookies.get("username");
      console.log(username); */
    },


    getValuesSearch: async function () {

      var that = this;

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apisearchads.getValuesSearch(
        window.urlSiteCareers
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from getValuesSearch", res);


        that.companyCarousel = res.data.company_carousel;

        that.funcArea = res.data.funcArea;
        that.regioni = res.data.regioni;
        that.comuniTemp = res.data.comuni;

        that.funcAreaSearch = "0";
        that.regioneSearch = "0";

        that.pathLogo = res.data.file_logo;


        that.txtPrivacy = res.data.txt_privacy;

        that.txtTermOfUse = res.data.txt_term_of_use;

        that.txtPrivacyHome = res.data.txt_privacy_home;


        // txtTermOfUse


        window.$cookies.set("url_logo_conf", res.data.file_logo, "9y");


        that.adsCities = res.data.query_tot_cities;

        that.adsIndustries = res.data.query_tot_industries;

        // browse job by functional area
        var v_tot_func_area = res.data.query_tot_func_area;

        for (var x = 0; x < v_tot_func_area.length; x++) {

          var txt = `<li class="col-md-4 col-sm-6"><a class='clFuncArea' style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_func_area[x].idsubcategory + `' title=""` + v_tot_func_area[x].description + `"">` + v_tot_func_area[x].description + ` <span>(` + v_tot_func_area[x].v_tot + `)</span></a></li>`;

          $("#idTabFyncArea").append(txt);

        }



        // quick links jobs by functional area
        for (var x2 = 0; x2 < v_tot_func_area.length; x2++) {

          if (x2 <= 2) {

            var txt2 = `<li><a class='clFuncArea' style="font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_func_area[x2].idsubcategory + `' title=""` + v_tot_func_area[x2].description + `"">` + v_tot_func_area[x2].description + `</a></li>`;

            $("#jobsByFuncAreaQuickLinks").append(txt2);

            console.log("ok");


          }


        }



        // quick links jobs by industries

        var v_tot_industries = that.adsIndustries;

        for (var x3 = 0; x3 < v_tot_industries.length; x3++) {

          var txt3 = `<li><a class='clIndustries' style="font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_industries[x3].idcategory + `' title=""` + v_tot_industries[x3].v_nome + `"">` + v_tot_industries[x3].v_nome + `</a></li>`;




          $("#jobsByIndustryQuickLinks").append(txt3);




        }







        $("#tbFunctionalArea").removeClass("cl-tab");
        $("#tbFunctionalArea").addClass("cl-tab-select");

        $(".bttxt").html(res.data.txt_powered_by);
        $(".txtInfoCompany").html(res.data.txt_azi_dx_footer);

        // immagine sfondo header

        $(".searchwrap").css("background", "url('')");


        if (window.innerWidth <= 768) {

          if (res.data.file_img_sfondo_header != null) {

            if (res.data.file_img_sfondo_header != "") {

              var val_css = "url('" + res.data.file_img_sfondo_header + "')";
              var prop_css = "background-image";

              $(".searchwrap").css(prop_css, val_css);

              $(".searchwrap").css("background-size", "cover");

              $(".searchwrap").css("background-repeat", "no-repeat");

              $(".searchwrap").css("background-position", "right");

            }

          }


        }
        else {

          if (res.data.file_img_sfondo_header != null) {

            if (res.data.file_img_sfondo_header != "") {

              var val_css3 = "url('" + res.data.file_img_sfondo_header + "')";
              var prop_css3 = "background-image";

              $(".searchwrap").css(prop_css3, val_css3);

              $(".searchwrap").css("background-size", "cover");

              $(".searchwrap").css("background-repeat", "no-repeat");

              $(".searchwrap").css("background-position", "center");

            }

          }

        }




        // immagine sfondo parallax

        $(".userloginbox").css("background", "url('')");



        if (res.data.file_img_sfondo_parallax != null) {

          if (res.data.file_img_sfondo_parallax != "") {

            var val_css2 = "url('" + res.data.file_img_sfondo_parallax + "') no-repeat center";
            var prop_css2 = "background";

            $(".userloginbox").css(prop_css2, val_css2);

            $(".userloginbox").css("background-attachment", "fixed");

            $(".userloginbox").css("background-size", "cover");

            $(".userloginbox").css("padding", "40px 0 120px 0");

            $(".userloginbox").css("text-align", "center");



          }


        }



        // featured job
        $("#divContFeaturedJobs").html(res.data.html_feat_job);
        $("#divContLatestJobs").html(res.data.html_latest_job);




        //     $("#sectionCarousel").hide();

        if (that.companyCarousel.length == 0) {

          $("#sectionCarousel").hide();

        }


      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );




    },

    registerAction: async function () {
      //const auth = { user: this.username, password: this.password };
      // Correct username is 'foo' and password is 'bar'
      //const url = "https://app.emtool.eu/api/emt/register";

      const url = "https://app.emtool.eu/registerUserForCompanyPost";

      //const url = "http://emtool.local/registerUserForCompanyPost";


      this.success = false;
      this.error = null;

      let formData = new FormData();

      formData.append("context", "2recruit");

      if (this.input.username != undefined)
        formData.append("login", this.input.username);

      if (this.input.password != undefined)
        formData.append("password", this.input.password);

      formData.append("companyname", "7");

      if (this.input.name != undefined)
        formData.append("name", this.input.name);

      if (this.input.surname != undefined)
        formData.append("surname", this.input.surname);

      if (this.input.email != undefined)
        formData.append("email", this.input.email);

      if (this.input.phone != undefined)
        formData.append("phone", this.input.phone);

      // if (this.input.namecompany != undefined)
      //   formData.append("namecompany", this.input.namecompany);

      formData.append("app", "2recruit");

      formData.append("is_demo", "S");



      try {
        await this.axios
          .post(url, formData, { "content-type": "text/json" })
          .then((result) => {
            var respo = result.data;
            this.success = true;
            // console.log( respo   );

            setTimeout(() => {
              this.showSpinner = false;

              var msgError = "";
              console.log("re", respo.errors);



              if (respo.errors && !this.isEmpty(respo.errors)) {
                console.log("Errori", respo.errors);
                var err = respo.errors;

                Object.entries(err).forEach(([key, value]) => {
                  console.log(`${key} ${value}`);
                  msgError += `${value}\r\n `;
                });

                if (msgError) {
                  this.$swal({ icon: "error", text: msgError });

                }


              }
              else {
                this.$swal({
                  icon: "success",
                  text: "Please check your email",
                  confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_careers.png' />",
                });

                this.$router.push({
                  path: "/",
                });


              }
              //this.$swal("You are logged in!!!");

              /*     window.$cookies.set("token", respo.token , "9y");
                  window.$cookies.set("system", respo.system,"9y");
                  window.$cookies.set("token_system", respo.token_system,"9y");
                  window.$cookies.set("username", respo.username,"9y");
                  window.$cookies.set("user_id", respo.user_id,"9y");
                  window.$cookies.set("namesurname", respo.namesurname,"9y"); */

              this.$root.$children[0].curDipTop = respo.namesurname;

              //router.push({ path: "/" });

            }, 1);
          });
      } catch (err) {
        this.success = false;
        console.log(err.message);
        this.$swal({ icon: "error", text: "Please check your data" });

        this.showSpinner = false;
        //  console.log(   response);

        //   this.error = err.message;
      }
    },

    register() {
      this.showSpinner = true;
      this.getCookie();

      this.registerAction();
      /*     if (this.input.username != "" && this.input.password != "") {
        if (
          this.input.username == this.$parent.mockAccount.username &&
          this.input.password == this.$parent.mockAccount.password
        ) {
          this.$emit("authenticated", true);
          this.$router.replace({ name: "secure" });
        } else {
          console.log("The username and / or password is incorrect");
        }
      } else {
        console.log("A username and password must be present");
      } */
    },
  },
};
</script>


<style>
#fldValueOtpNewsletter {
  text-align: center !important;
}


#fldValueOtpUnsubNewsletter {
  text-align: center !important;
}


.jobintLatest {
  min-height: 195px !important;
}

.clTermOfUse {
  width: 100%;
  overflow-y: auto;
  min-height: 391px;
  max-height: 391px;
  margin-left: 5px;
  margin-top: 5px;
  padding-right: 17px;
}


.clTermOfUse h2 {
  font-family: 'Orbitron', sans-serif !important;
}

.clTermOfUse p {
  font-family: 'Orbitron', sans-serif !important;
}

.clTermOfUse strong {
  font-family: 'Orbitron', sans-serif !important;
  font-weight: bold;
}






.clPrivacyHome {
  width: 100%;
  overflow-y: auto;
  min-height: 391px;
  max-height: 391px;
  margin-left: 5px;
  margin-top: 5px;
  padding-right: 17px;
}


.clPrivacyHome h2 {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacyHome p {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacyHome strong {
  font-family: 'Orbitron', sans-serif !important;
  font-weight: bold;
}













.clPipe {
  border: 1px solid rgb(102, 102, 102);
  height: 30px;
  margin-top: 20px;
}

.ads-search .clButtonHeader {
  width: 60px !important;
  margin-top: -9px !important;
}

.ads-search .clButtonJobAll {
  width: 55px !important;
  margin-top: -9px !important;
}

.ads-search .clButtonRefresh {
  width: 31px !important;
  margin-top: 2px !important;
}

.searchbarLatestJob .form-control {
  height: 50px;
  padding: 13px 15px;
  border: 1px solid;
  font-size: 18px;
}


.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}


.imgSearchJob {
  margin-top: -2px;
  margin-left: -8px;
  min-width: 269px;
  cursor: pointer;
  box-shadow: 0px 20px 40px 5px rgb(0 0 0 / 20%);
}


.btnSearchJob {
  font-family: Orbitron, sans-serif !important;
  background: '/careers/img/search-job.png' !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin-left: -12px !important;
  width: 276px !important
}




.cl-lj-1 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 17px !important;
}

.cl-fj-1 {
  font-family: 'Orbitron', sans-serif !important;
}

.cl-fj-2 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.cl-fj-3 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.cl-fj-4 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 15px !important;
}

.cl-fj-5 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 10px !important;
}

.cl-fj-6 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #000 !important;
}


.cl-fj-7 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 8px !important;
}


.cl-fj-8 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 8px !important;
  font-weight: 400 !important;
  color: #000 !important;
}


.cl-lj-2 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 13px !important;
}

.cl-portale-1 {
  font-family: 'Orbitron', sans-serif !important;
  color: white !important;
}


.howlist h4 {
  font-family: 'Orbitron', sans-serif !important;
}

.jobslist li .company {
  color: #999;
  padding: 7px 0;
  padding-top: 0px !important;
}

.titleTop h3 {
  font-family: 'Orbitron', sans-serif !important;
}

.titleTop span {
  font-family: 'Orbitron', sans-serif !important;
}

.navbar-nav {
  flex-direction: inherit !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  list-style: none !important;
}


.bttxt {
  color: #888 !important;
  line-height: 24px !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
}

.bttxt a {
  color: #888 !important;
  font-weight: 600 !important;
}

.address {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  min-width: 300px !important;
}

.textBold {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.email a {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.phone a {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.footerWrap .email:before {
  position: absolute !important;
  left: 0 !important;
  content: '\f003' !important;
  font-family: 'FontAwesome' !important;
  font-size: 18px !important;
}

ion-select {
  font-size: 18px !important;
  --placeholder-opacity: 1 !important;
  font-family: 'Orbitron', sans-serif !important;
}

.catelist li a:before {
  position: absolute !important;
  left: 0 !important;
  content: '\f0da' !important;
  font-family: 'FontAwesome' !important;
  font-weight: 400 !important;
}

.howlist li .iconcircle {
  text-align: center !important;
  margin-bottom: 15px !important;
  display: inline-block !important;
}

.howlist li .iconcircle i {
  font-size: 48px !important;
  color: #444 !important;
}

.fa {
  display: inline-block !important;
  font: normal normal normal 14px/1 FontAwesome !important;
  font-size: inherit !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.fa-user:before {
  content: '\f007' !important;
}

button {
  font-family: 'Orbitron', sans-serif !important;
}

.select-ios .select-placeholder {
  color: #000;
  font-size: 15px;
  font-family: 'Orbitron', sans-serif !important;
}

.idsubcategory {
  min-width: 521px;
}

.idregione {
  min-width: 225px;
}

.idcomune {
  min-width: 225px;
}

.companyid {
  min-width: 223px;
}

.ads-search .cl-tab-select {
  font-size: 16px !important;
  background: #444 !important;
  color: white !important;
  font-family: 'Orbitron', sans-serif !important;
  font-weight: 700 !important;
  padding: 15px 30px !important;
  border-radius: 5px 5px 0 0 !important;
  border-color: #444 !important;
}

.ads-search .newjbox li .jobint {
  box-shadow: none !important;
  border-radius: 0 !important;
  background: #f9fafb !important;
  border-bottom-color: #F2F9FF !important;
  max-height: 180px !important;
}

.ads-search .cl-tab {
  font-size: 16px !important;
  background: none !important;
  color: #666 !important;
  font-family: 'Orbitron', sans-serif !important;
  font-weight: 400 !important;
  padding: 15px 30px !important;
}

.cl-title-jobsby {}

.v-overflow-bt-padding {
  padding-bottom: 6px !important
}

.imgViewDetail {
  width: 40px;
}

.colImgViewDetails {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.divFeatMobile {
  display: none;
}

.imageCarousel {
  width: 120px;
  cursor: pointer;
}


.divLatestMobile {
  display: none;
}

.greybg2 {
  background: rgb(0 0 0 / 8%)
}


/* .ads-search .v-label {
  font-size: 1em !important;
} */

.ads-search .v-label {
  font-size: 12px !important;
}

/* .ads-search .v-text-field input {
  font-size: 1em !important;
} */

.ads-search .v-text-field input {
  font-size: 12px !important;
}



@media screen and (max-width: 768px) {

  .searchwrap {
    margin-top: 27px !important;
  }

  .idsubcategory {
    min-width: 278px;
  }

  .idregione {
    min-width: 278px;
  }

  .idcomune {
    min-width: 278px;
  }


  .companyid {
    min-width: 278px;
  }

  .ads-search .cl-tab {
    font-size: 1rem !important;
    background: none !important;
    color: #666 !important;
    padding: 8px !important;
    width: 249px !important;
    min-width: 249px !important;
  }

  .ads-search .cl-tab-select {
    font-size: 1rem !important;
    background: #444 !important;
    color: white !important;
    padding: 8px !important;
    width: 249px !important;
    min-width: 249px !important;
  }

  .cl-title-jobsby {
    height: 108px !important;
  }

  .v-overflow-bt-padding {
    padding-bottom: 40px !important
  }

  .bttxt {
    color: #888 !important;
    line-height: 24px !important;
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
    display: block;
    align-items: center;
  }

  .bttxt a {
    color: #888 !important;
    font-weight: 600 !important;
  }


  .btnSearchJob {
    font-family: Orbitron, sans-serif !important;
    background: url('/img/search-job.png') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin-left: -10px !important;
    width: 336px !important
  }

  .imgSearchJob {
    margin-top: -18px;
    margin-left: -1px;
    min-width: 319px;
    cursor: pointer;
    box-shadow: 0px 20px 40px 5px rgb(0 0 0 / 20%);
  }

  .ads-search .clButtonHeader {
    min-width: 40px !important;
    margin-top: -6px !important;
  }

  .ads-search .clButtonRefresh {
    min-width: 20px !important;
    margin-top: 1px !important;
  }

  .ads-search .newjbox li .jobint {
    box-shadow: none !important;
    border-radius: 0 !important;
    background: #f9fafb !important;
    border-bottom-color: #F2F9FF !important;
    max-height: 184px !important;
  }


  .clPipe {
    border: 1px solid rgb(102, 102, 102);
    height: 17px;
    margin-top: 18px;
  }

  .clTermOfUse {
    width: 100%;
    overflow-y: auto;
    min-height: 391px;
    max-height: 391px;
    margin-left: 5px;
    margin-top: 5px;
    padding-right: 17px;
  }

  .ads-search .clButtonJobAll {
    min-width: 20px !important;
    margin-top: 1px !important;
  }

  .divFeatNoMobile {
    display: none;
  }

  .divFeatMobile {
    display: block;
  }

  .viewAds {
    display: flex;

  }

  .divLatestNoMobile {
    display: none;
  }

  .divLatestMobile {
    display: block;
  }

  .jobintLatest {
    min-height: 207px !important;
  }


}
</style>

<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
  display: none;
}

#login {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  /*   margin-top: 200px; */
  padding: 20px;
}

#nav,
.v-sheet {
  display: none;
}
</style>


